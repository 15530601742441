import { apiRequest } from "../util/apiUtils";

export const getAlbumList = async (params) => {
    try {
      const res = await apiRequest("get", "gallery/list", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  export const changeAlbumStatus = async (payload = {}) => {
    try {
      const res = await apiRequest("patch", `gallery/status`, {
        body: payload,
      });
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const albumDetails = async (params) => {
    try {
      const res = await apiRequest("get", `gallery/details/${params?.id}`);
  
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const addAlbum = async (params) => {
    try {
      const res = await apiRequest(
        `${params.id ? "put" : "post"}`,
        `gallery/${params?.id ? `edit/${params.id}` : "add"}`,
        {
          body: params,
        },
        "multipart/form-data"
      );
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  export const saveAlbumOrdering = async (params) => {
    try {
      const res = await apiRequest("patch", "gallery/save-ordering", { body: params });
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const getCategoryList = async (params) => {
    try {
      const res = await apiRequest("get", "category/list", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };