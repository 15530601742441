import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getVerifyToken } from "../services/ProfileService";

export const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  const token_ = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");
  const accessRole = Array.isArray(props.getAccess) ? props.getAccess : [];
  const path = window.location.pathname;
  useEffect(() => {
    const checkData = async () => {
      try {
        const res = await getVerifyToken();
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/admin/signin");
          setAuth(false);
        } else {
          setAuth(true);
          if (res?.status === 200) {
            localStorage.setItem("role", res?.data?.data?.roleCode);
          }
        }
      } catch (error) {
        localStorage.removeItem("auth_token");
        navigate("/admin/signin");
        setAuth(false);
      } finally {
        setIsLoader(false);
      }
    };

    if (!token_) {
      setIsLoader(false);
      localStorage.removeItem("role");
      navigate("/admin/signin");
    } else {
      checkData();
    }
  }, [token_, navigate]);

  useEffect(() => {
    const checkAccessRole = () => {
      const hasAccess = accessRole.includes(role);
      if (!hasAccess) {
        if (token_) {
          if (role === "ADMIN") {
            navigate("/admin/dashboard");
          } else {
            navigate("/admin/signin");
          }
        }
      }
    };

    checkAccessRole();
  }, [role, path, accessRole, navigate, token_]);

  return auth ? props.children : null;
};
