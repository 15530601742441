import { apiRequest } from "../util/apiUtils";

export const getVerifyToken = async (params) => {
  try {
    const res = await apiRequest("post", "verify-token", {});
    return res;
  } catch (error) {
    return error;
  }
};
export const profileDetails = async () => {
  try {
    const res = await apiRequest("get", `profile`);
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const profileUpdate = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      `profile/edit`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const deleteImage = async (payload = {}) => {
  try {
    const res = await apiRequest("delete", `profile/delete/image`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const changePassword = async (params) => {
  try {
    const res = await apiRequest("patch", `profile/change-password`, {
      body: {
        ...params,
      },
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};