import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../forms/Button";
import { apiRequest } from "../../util/apiUtils";
import NewModal from "../shared/NewModal";

const DeleteModal = ({
  open = false,
  onClose = () => {},
  count,
  setCount,
  customMessage = "",
  customRecord = "",
  customIcon = "",
  customButtonLabel = "",
  getResponse = () => {},
  data = {
    apiEndpoint: "/",
    method: "delete",
    targetId: null,
    data: {},
    payload: {
      body: {
        ids: null,
      },
    },
  },
}) => {
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    try {
      const res = await apiRequest(
        data?.method,
        data?.apiEndpoint + (data?.targetId ? "/" + data?.targetId : ""),
        {
          body: data?.payload?.body,
        }
      );
      if (res?.status === 200) {
        setLoading(false);
        getResponse({
          ...res,
          data: {
            ...res?.data,
            ...data,
          },
        });
        setCount(count - 1);
      } else {
        setLoading(false);
        getResponse(res);
        setCount(count - 1);
      }
      return;
    } catch (error) {
      setLoading(false);
      getResponse(error);
      return;
    }
  };
  return (
    <NewModal
    
      isOpen={open}
      setIsOpen={() => {
        if (loading) {
          toast.info("Process is running please wait");
        } else {
          onClose();
        }
      }}
      size="sm"
      titleShow={false}
      watermark={false}
    >
      <div className="text-center font-bold text-lg flex gap-2 justify-center text-red-500">
        <span>
          <i
            className={
              customIcon ? customIcon : "fa-regular fa-circle-exclamation"
            }
          ></i>
        </span>
        {customRecord ? customRecord : <>Delete Record</>}
      </div>
      <div className="text-center my-3 text-slate-500">
        {customMessage ? (
          customMessage
        ) : (
          <>
            You are trying to delete the record,
            <br /> click &quot;Delete&quot; below.
          </>
        )}
      </div>
      <div className="flex gap-3 justify-center !mt-6">
      <Button
            buttonIconPosition={"left"}
            buttonIcon={"fa-regular fa-close"}
            buttonClasses={"w-36 justify-center !border-black !text-black !bg-white"}
            buttonLabel={"Cancel"}
            buttonFunction={() => {
              onClose();
            }}
          />
        <Button
          buttonFunction={deleteHandler}
          buttonIconPosition={"left"}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : customIcon
              ? customIcon
              : "fa-regular fa-trash-can"
          }
          buttonClasses={
            "!bg-red-500 !text-white w-36 justify-center hover:!bg-gray-800 hover:!text-white !duration-100"
          }
          buttonLabel={customButtonLabel ? customButtonLabel : "Delete"}
        />
      </div>
    </NewModal>
  );
};

export default DeleteModal;
