import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  changeAlbumStatus,
  getAlbumList,
} from "../../../services/AlbumService";
import { status_filter } from "../../../components/constant/constant";
import Select from "../../../components/forms/Select";
import Search from "../../../components/forms/Search";
import Button from "../../../components/forms/Button";
import Table from "../../../components/elements/table/Table";
import PageHeader from "../../../layouts/shared/PageHeader";
import Image from "../../../components/elements/Image";
import ManageAlbum from "./ManageAlbum";
import DeleteModal from "../../../components/common/DeleteModal";
import { useDebounce } from "../../../hooks/useDebounce";

const AlbumList = () => {
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState([]);
  const [type , setType] = useState([2,3]);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [keyword, setKeyword] = useState("");
  const [albumCount, setAlbumCount] = useState("");
  const search = useDebounce(keyword, 500);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [albumModal, setalbumModal] = useState({
    isOpen: false,
    data: "",
  });
  const data = [
    { name: "All Items", value: "All" },
    { name: "5 Items", value: 5 },
    { name: "10 Items", value: 10 },
    { name: "20 Items", value: 20 },
    { name: "30 Items", value: 30 },
    { name: "50 Items", value: 50 },
    { name: "100 Items", value: 100 },
  ];
  const handleChange = (value) => {
    if (value?.value === "All") {
      setOffset(0);
      setLimit({ name: "All Items", value: count });
      setCurrentPage(1);
      setSelectedItems([]);
    } else {
      setOffset(0);
      setLimit(value);
      setSelectedItems([]);
    }
  };
  // loading the admin list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const status_filter = {};
    if (status && Array.isArray(status) && status.length > 0) {
      status.forEach((item, i) => (status_filter[`status[${i}]`] = item));
    }
    const type_filter = {};
    if (type && Array.isArray(type) && type.length > 0) {
      type.forEach((item, i) => {
        type_filter[`type[${i}]`] = item;
      });
    }
    if (limit && sort) {
      getAlbumList({
        // sortQuery: sort,
        keyword: search,
        limit: limit?.value,
        page: currentPage,
        ...status_filter,
        ...type_filter,
      }).then((res) => {
        if (res && res?.docs?.status === 200) {
          setList({
            loading: false,
            data: res?.docs?.data,
            pageCount: res?.docs?.metadata?.totalPages,
            totalItem: res?.docs?.metadata?.totalDocs,
          });
          setCount(res?.docs?.metadata?.totalDocs);
          setPagination({
            hasNextPage: res?.docs?.metadata?.hasNextPage,
            hasPrevPage: res?.docs?.metadata?.hasPrevPage,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast?.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, limit, status, currentPage, search,type]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Status Change >>>>>>>>>>

  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem?.status === 1 ? 2 : 1;
    const res = await changeAlbumStatus({
      ids: [glossaryItem?._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, status: newStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };
  const getDeleteResponse = (res) => {
    if (res?.status === 200) {
      setList((prev) => {
        return {
          ...prev,
          data: prev.data.filter((item) => item._id !== res?.data?.data?._id),
          loading: false,
        };
      });
      setDeleteModal({ isOpen: false });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };

  const tableHeadData = [
    {
      _id: 1,
      name: "Album",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 2,
      name: "Gallery Images",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 3,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: statusFilterData,
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setStatus(data?.map((item) => item?.value));
        } else {
          setStatus([]);
        }
        setCurrentPage(1);
      },
    },
    {
      _id: 4,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];
  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: 1,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.title,
        image: item?.image,
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div className="flex items-center">
            {Array.isArray(item?.galleriesData) &&
            item?.galleriesData?.length > 0 ? (
              <>
                {item?.galleriesData.map((item, index) => (
                  <div
                    key={index}
                    className={`relative w-[40px] h-[40px] overflow-hidden mr-3 ${
                      index > 0 ? "-ml-6" : ""
                    }`}
                  >
                    <Image
                      src={item?.image ? item?.image : "Images"}
                      alt="Gallery"
                      width={48}
                      height={48}
                      isLoader={false}
                      className="rounded-full w-10 aspect-square border-2 border-white"
                    />
                  </div>
                ))}
                {item?.totalGalleryCount - 2 > 0 && (
                  <div className="relative">
                    <span className="text-xs">
                      <i className="fa-regular fa-plus"></i>
                      {item?.totalGalleryCount - 2}
                      {`${
                        item?.totalGalleryCount - 2 === 1 ? "more" : "mores"
                      }`}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <span className="text-xs text-gray-500">No Gallery</span>
            )}
          </div>
        ),
      },

      {
        _id: 3,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 4,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
  ...(item?.type === 3
    ? [
        {
          _id: 1,
          name: "Edit",
          icon: "fa-regular fa-pen-to-square",
          standout: false,
          onClick: () => {
            setalbumModal({
              isOpen: true,
              data: item?._id,
            });
          },
        },
      ]
    : [
        {
          _id: 1,
          name: "Edit",
          icon: "fa-regular fa-pen-to-square",
          standout: false,
          onClick: () => {
            setalbumModal({
              isOpen: true,
              data: item?._id,
            });
          },
        },
        {
          _id: 2,
          name: "Manage Gallery",
          icon: "fa-regular fa-camera",
          link: `/album/gallery/${item?._id}`,
          standout: false,
        },
        {
          _id: 3,
          name: "Delete",
          icon: "fa-regular fa-trash-can",
          standout: true,
          onClick: () => {
            setDeleteModal({
              isOpen: true,
              apiEndpoint: `gallery/delete-all`,
              method: "patch",
              data: item,
              payload: {
                body: {
                  id: item?._id,
                },
              },
            });
          },
        },
      ]),
],

      },
    ],
  }));

  return (
    <>
      <PageHeader
        title={"Albums"}
        headerActions={() => (
          <Button
            buttonHasLink={false}
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus !text-sm md:text-2xl"}
            buttonLabelClasses={"text-xs md:!text-base"}
            // buttonClasses={"!px-4 md:!px-7"}
            buttonIconPosition={"left"}
            buttonLabel={"Add Album"}
            buttonFunction={() =>
              setalbumModal({
                isOpen: true,
                data: "",
              })
            }
          />
        )}
      />
      <div className="w-full py-4 px-6">
        <div className="mb-6 flex gap-3">
          <div className="w-40">
            <Select
              dropdownData={data}
              placeholder={"Select Limit"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              selectedValue={limit?.value}
              onChange={handleChange}
            />
          </div>
          <div className="relative md:ml-auto flex justify-end items-center space-y-2 md:gap-3">
            <div className="relative w-full">
              <Search
                placeholder={"search"}
                setInput={setKeyword}
                valueReset={() => {
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
        </div>

        <div className="-mx-8">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6 min-h-[calc(100vh-136px)]"}
            isLoder={list?.loading}
            pageCount={list?.pageCount}
            currentPage={currentPage}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedItems([]);
            }}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
            tableActionDropdownContainer={"!w-48"}
          />
        </div>
      </div>
      <ManageAlbum
        albumModal={albumModal}
        setalbumModal={setalbumModal}
        setList={setList}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        data={{
          apiEndpoint: deleteModal?.apiEndpoint,
          method: deleteModal?.method,
          data: deleteModal?.data,
          payload: deleteModal?.payload,
        }}
        count={albumCount}
        setCount={setAlbumCount}
        onClose={() => setDeleteModal({ isOpen: false })}
        getResponse={getDeleteResponse}
      />
    </>
  );
};

export default AlbumList;
