import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../layouts/shared/PageHeader";
import Button from "../../../components/forms/Button";
import Select from "../../../components/forms/Select";
import Search from "../../../components/forms/Search";
import colImg from "../../../assets/images/user.webp";
import Table from "../../../components/elements/table/Table";

export default function Collections() {
  const [list, setList] = useState({
    loading: true,
    data: [
      {
        _id: "673f23ae2adfd6205f128040",
        name: "Beaches",
        image: colImg,
        colabCount: 8,
        catCount: 12,
        status: 1,
      },
      {
        _id: "673f23ae2adfd6205f128040",
        name: "Restraunts",
        image: colImg,
        colabCount: 8,
        catCount: 12,
        status: 1,
      },
      {
        _id: "673f23ae2adfd6205f128040",
        name: "Best Barbers",
        image: colImg,
        colabCount: 8,
        catCount: 12,
        status: 1,
      },
      {
        _id: "673f23ae2adfd6205f128040",
        name: "Best Barbers",
        image: colImg,
        colabCount: 8,
        catCount: 12,
        status: 1,
      },
      {
        _id: "673f23ae2adfd6205f128040",
        name: "Best Barbers",
        image: colImg,
        colabCount: 8,
        catCount: 12,
        status: 1,
      },
    ],
    pageCount: 0,
    totalItem: 0,
  });

  const loadList = useCallback(() => {}, []);

  useEffect(() => loadList(), [loadList]);

  const tableHeadData = [
    {
      _id: 1,
      type: "product",
      name: "Collection",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      name: "Collaborators",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      name: "Categories",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 4,
      name: "Status",
      align: "center",
      isSort: true,
    },
    { _id: 5, name: "Action", align: "right", isSort: false, isFilter: false },
  ];

  const formattedlist = list.data.map((glossaryItem) => ({
    _id: glossaryItem._id,
    rowData: [
      {
        _id: 1,
        type: "product",
        align: "left",
        data: glossaryItem?.name || "N/A",
        image: glossaryItem?.image || "N/A",
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        data: (
          <div>
            <div className="flex items-center  capitalize justify-start">
              <div
                className={
                  "text-xs font-medium leading-tight rounded py-1 px-2 bg-sky-100 text-sky-600"
                }
              >
                {glossaryItem?.colabCount}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        data: (
          <div>
            <div className="flex items-center  capitalize justify-start">
              <div
                className={
                  "text-xs font-medium leading-tight rounded py-1 px-2 bg-sky-100 text-sky-600"
                }
              >
                {glossaryItem?.catCount}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 4,
        type: "status",
        align: "left",
        statusLabel: glossaryItem.status === 1 ? "Active" : "Inactive",
        statusType: glossaryItem.status === 1 ? "success" : "danger",
      },

      {
        _id: 5,
        type: "action",
        align: "right",
        tdClasses: "w-[5%]",
        actionData: [
          {
            _id: 1,
            label: "Edit",
            icon: "fa-pen-to-square",
            link: `/admin/plans/manage?id=${glossaryItem._id}`,
            standout: false,
          },
          {
            _id: 2,
            label: "Delete",
            icon: "fa-trash-can",
            standout: true,
            onClick: () => {},
          },
        ],
      },
    ],
  }));

  return (
    <>
      <PageHeader
        title={"Collections"}
        headerActions={() => (
          <Button
            buttonHasLink={true}
            buttonLink={"/admin/collections/manage"}
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus !text-sm md:text-2xl"}
            buttonLabelClasses={"text-xs md:!text-base"}
            buttonClasses={"!px-4 md:!px-7"}
            buttonIconPosition={"left"}
            buttonLabel={"Add Collection"}
          />
        )}
      />
      <div className="relative flex justify-between flex-col md:flex-row md:items-center py-2 px-6">
        <div className="relative w-full md:w-44 cursor-pointer">
          <Select
            selectedValue={10}
            dropdownData={[
              { name: "5 Items", value: 5 },
              { name: "10 Items", value: 10 },
              { name: "20 Items", value: 20 },
              { name: "30 Items", value: 30 },
              { name: "50 Items", value: 50 },
              { name: "100 Items", value: 100 },
            ]}
          />
        </div>
        <div className="relative md:ml-auto flex justify-end items-center space-y-2 md:gap-3">
          <div className="relative w-full">
            <Search divClasses={"w-full md:!w-80"} placeholder={"Search"} />
          </div>
        </div>
      </div>
      <div className="py-2 px-6">
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            isLoading={false}
            tableData={formattedlist}
            tableHeadData={tableHeadData}
          />
        </div>
      </div>
    </>
  );
}
