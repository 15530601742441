export const initialsValue = (str = "") => {
  const strArr = str?.split(" ");
  const initials =
    (strArr &&
      Array.isArray(strArr) &&
      strArr[0]
        .slice(0, 1)
        .concat(strArr?.length > 1 ? strArr.at(-1).slice(0, 1) : "")
        .toUpperCase()) ||
    [];
  return initials;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleOnDragEnd = (
  result,
  list = [],
  setList = () => {},
  callback = () => {}
) => {
  if (!result.destination) return;

  const items = reorder(list, result.source.index, result.destination.index);
  setList(items);
  callback(items);
};

export const createFilter = (filterArr = [], key) => {
  if (filterArr && typeof filterArr[0] === "boolean") {
    return { [key]: filterArr[0] };
  }
  return Array.isArray(filterArr) && filterArr.length > 0
    ? filterArr.reduce((acc, item, i) => {
        acc[`${key}[${i}]`] = item;
        return acc;
      }, {})
    : {};
};

export const handleFilterChange = (name, value, setFilters = () => {}) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    [name]: value,
  }));
};
