import React from "react";
import SidebarGroup from "./SidebarGroup";
import SidebarItem from "./SidebarItem";

export default function SidebarBlock({ data }) {
  return (
    <>
      {data.isSub ? (
        <div className="relative group/parent">
          <SidebarGroup data={data} />
        </div>
      ) : (
        <div className="relative group/link">
          <SidebarItem data={data} />
        </div>
      )}
    </>
  );
}
