import React from "react";
import NoImage from "../../assets/images/no-image.png";
import Image from "../elements/Image";

const UserDetailsDetailsSkeleton = () => {
  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex items-center justify-between pb-4">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          <div className="bg-gray-300 animate-pulse h-6 w-40 rounded"></div>{" "}
        </div>
        <div className="ml-auto">
          <div className="bg-gray-300 animate-pulse w-32 h-10 rounded-md"></div>{" "}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="bg-white border border-slate-200 rounded-xl shadow-md max-w-full w-full py-4 px-0 transition-all duration-300">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-1 text-center md:mb-0 flex flex-col items-center">
              <div className="overflow-hidden border-4 border-slate-200 rounded-full bg-white flex items-center justify-center w-36 h-36 mx-auto">
                <div className="bg-gray-300 animate-pulse w-full h-full rounded-full flex items-center justify-center">
                  <Image
                    src={NoImage}
                    fill
                    className="object-cover opacity-50"
                    alt=""
                  />
                </div>
              </div>
              <div className="bg-gray-300 animate-pulse h-6 w-32 mx-auto mt-2 rounded"></div>{" "}
            </div>

            <div className="col-span-3 flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8">
              <div className="flex-1">
                <div className="bg-gray-300 animate-pulse h-6 w-40 rounded mb-4"></div>{" "}
                <div className="flex flex-col gap-2 w-full">
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>{" "}
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                </div>
              </div>

              <div className="flex-1">
                <div className="bg-gray-300 animate-pulse h-6 w-40 rounded mb-4"></div>{" "}
                <div className="flex flex-col gap-2 w-full">
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>{" "}
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                  <div className="bg-gray-300 animate-pulse h-6 w-3/4 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-4 flex space-x-4 w-full">
        <div className="bg-gray-300 animate-pulse h-8 flex-1 rounded"></div>
        <div className="bg-gray-300 animate-pulse h-8 flex-1 rounded"></div>
        <div className="bg-gray-300 animate-pulse h-8 flex-1 rounded"></div>
      </div>
      <div className="relative mt-4  space-y-4 w-full">
        <div className="flex space-x-2">
          <div className="bg-gray-300 animate-pulse h-8 w-32 rounded"></div>
          <div className="bg-gray-300 animate-pulse h-8 w-60 rounded"></div>
        </div>
        <div className="bg-gray-300 animate-pulse h-8  rounded"></div>
        <div className="bg-gray-300 animate-pulse h-8  rounded"></div>
        <div className="bg-gray-300 animate-pulse h-8  rounded"></div>
        <div className="bg-gray-300 animate-pulse h-8  rounded"></div>
      </div>
    </div>
  );
};

export default UserDetailsDetailsSkeleton;
