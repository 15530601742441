import React, { useCallback, useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import AdminSidebar from "../shared/AdminSidebar";
import AdminHeader from "../shared/AdminHeader";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { profileDetails } from "../../services/ProfileService";
import { setProfile } from "../../redux/slice/profileSlice,";
import { useSelector } from "react-redux";

export default function AdminLayout() {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const userAgent = window.navigator.userAgent;
    const mobileRegex = /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i;
    setIsMobile(mobileRegex.test(userAgent));
  }, []);

  const fetchProfile = useCallback(() => {
    profileDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
      }
    });
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <>
      <div className="h-screen">
        <div className="flex-shrink flex-grow flex">
          <div
            className={classNames(
              "absolute w-60 h-screen z-90 top-0 left-0 flex shadow-xl transition-all duration-200 ease-in-out",
              !isMenuOpen ? "-translate-x-full" : "translate-x-0"
            )}
          >
            <AdminSidebar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
          </div>
          <div
            className={
              isMobile
                ? classNames(
                    "flex flex-col w-full transition-all duration-200 ease-in-out"
                  )
                : classNames(
                    "flex flex-col w-full transition-all duration-200 ease-in-out",
                    !isMenuOpen ? "pl-0" : "pl-60"
                  )
            }
          >
            <div className="relative w-full h-16 flex z-70">
              <AdminHeader isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
            </div>
            <div className="w-full bg-slate-100 h-screen max-h-[calc(100vh-4rem)] overflow-auto scrollbar">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
