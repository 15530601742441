import React from "react";

const DashboardSkeleton = () => {
  return (
    <div className="bg-transparent px-6 py-8">
      <div className="flex justify-between items-center mb-6">
        <div className="text-2xl font-bold">Dashboard</div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Users Card Skeleton */}

        <div className="relative flex items-center space-x-4 p-4 border border-indigo-500 bg-indigo-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-indigo-400">
          <div className="animate-pulse">
            <div className="w-12 h-12 bg-indigo-300 rounded-full"></div>{" "}
            <div className="absolute top-2 right-4 w-24 h-6 bg-indigo-300 rounded"></div>{" "}
            <div className="absolute bottom-2 right-4 w-12 h-8 bg-indigo-300 rounded"></div>{" "}
          </div>
        </div>

        {/* Albums Card Skeleton */}

        <div className="relative flex items-center space-x-4 p-4 border border-pink-500 bg-pink-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-pink-400">
          <div className="animate-pulse">
            <div className="w-12 h-12 bg-pink-300 rounded-full"></div>{" "}
            <div className="absolute top-2 right-4 w-24 h-6 bg-pink-300 rounded"></div>{" "}
            <div className="absolute bottom-2 right-4 w-12 h-8 bg-pink-300 rounded"></div>{" "}
          </div>
        </div>

        {/* Collections Card Skeleton */}

        <div className="relative flex items-center space-x-4 p-4 border border-cyan-500 bg-cyan-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-cyan-400">
          <div className="animate-pulse">
            <div className="w-12 h-12 bg-cyan-300 rounded-full"></div>{" "}
            <div className="absolute top-2 right-4 w-24 h-6 bg-cyan-300 rounded"></div>{" "}
            <div className="absolute bottom-2 right-4 w-12 h-8 bg-cyan-300 rounded"></div>{" "}
          </div>
        </div>

        {/* Businesses Card Skeleton */}

        <div className="relative flex items-center space-x-4 p-4 border border-green-600 bg-green-100 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-green-400">
          <div className="animate-pulse">
            <div className="w-12 h-12 bg-green-300 rounded-full"></div>{" "}
            <div className="absolute top-2 right-4 w-24 h-6 bg-green-300 rounded"></div>{" "}
            <div className="absolute bottom-2 right-4 w-12 h-8 bg-green-300 rounded"></div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
