import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "../elements/style/imageLoader.css";

const Image = ({
  src,
  width,
  height,
  alt,
  effect,
  className,
  isLoader = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative w-full h-full flex">
      {isLoading && (
        <div
          className={`absolute inset-0 flex items-center justify-center ${
            !isLoader && "hidden"
          }`}
        >
          <div className="spin-loader"></div>
        </div>
      )}
      <LazyLoadImage
        src={src}
        width={width}
        height={height}
        // crossOrigin="anonymous"
        alt={alt}
        effect={effect}
        className={`w-full h-full ${className} ${
          isLoading ? "opacity-0" : "opacity-100"
        } transition-opacity duration-500`}
        afterLoad={() => setIsLoading(false)}
        // onError={(e) => {
        //   e.target.src = "";
        // }}
      />
    </div>
  );
};

export default Image;
