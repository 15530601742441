import React from "react";
import Button from "../../components/forms/Button";
import UserDropdown from "./UserDropdown";

export default function AdminHeader({ isMenuOpen, setMenuOpen }) {
  return (
    <>
      <header className="relative w-full bg-slate-100 flex items-center px-6 z-80">
        <div className="mr-auto">
          <div className="flex items-center gap-4">
            <Button
              buttonHasLink={false}
              buttonIconPosition={"left"}
              buttonIcon={"fa-duotone fa-bars"}
              buttonClasses={
                "!px-0 w-10 !rounded-lg !text-2xl !bg-transparent !text-blue-950 !border-transparent !flex !items-center !justify-center hover:!border-slate-200 hover:!shadow-md hover:!bg-white"
              }
              buttonFunction={() => setMenuOpen(!isMenuOpen)}
            />
          </div>
        </div>
        <div className="ml-auto">
          <div className="flex items-center gap-4">
            <div className="relative">
              <UserDropdown xPlacement={"right"} role={"admin"} />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
