import React from "react";
import { classNames } from "../../helpers/classNames";

const Radio = ({
  value,
  divClass,
  radioName,
  isChecked,
  radioLabel,
  background,
  buttonIcon,
  checked,
  radioboxClass,
  radioLableClass,
  radioInputClass,
  onChange = () => {},
  ...props
}) => {
  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden flex items-center",
          divClass
        )}
      >
        <input
          type="radio"
          value={value}
          name={radioName}
          className={classNames(
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer",
            radioInputClass
          )}
          defaultChecked={isChecked}
          checked={checked}
          onChange={(e) =>
            onChange({
              value: e.target.value,
              checked: e.target.checked || false,
              name: e.target.name,
            })
          }
        />
        <div
          style={{ background }}
          className={classNames(
            "w-5 h-5 min-w-[20px] mr-3 border border-slate-300 rounded-full flex items-center justify-center text-[10px] bg-white text-black/0 peer-checked:bg-carnation-950 peer-checked:border-carnation-950 peer-checked:text-white",
            radioboxClass
          )}
        >
          <i className={classNames("fa-solid fa-fw fa-circle", buttonIcon)}></i>
        </div>
        {radioLabel && (
          <div
            className={classNames(
              "text-sm text-slate-500 w-full",
              radioLableClass
            )}
          >
            {radioLabel}
          </div>
        )}
      </div>
    </>
  );
};

export default Radio;
