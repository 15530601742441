import React from "react";
import TableFilter from "./TableFilter";
import TableSort from "./TableSort";
import { classNames } from "../../../helpers/classNames";
import Checkbox from "../../forms/Checkbox";

const Th = ({
  name,
  nameAlign,
  isSort,
  isFilter,
  isFilterSearch,
  dropdownData,
  type,
  onSort = () => {},
  onFilter = () => {},
  filterSearch = () => {},
  handleCheckboxChange,
  onCheckboxChange,
  checkboxChecked,
  ...props
}) => {
  const align = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
    "": "justify-start",
  };
  return (
    <>
      <th
        className={classNames(
          "py-2 px-2  text-left max-w-[240px] border-none bg-black first:rounded-tl-md last:rounded-tr-md",
          "last:right-0"
        )}
      >
        <div
          className={classNames("flex items-center w-full", align[nameAlign])}
        >
          <div className="text-sm font-semibold text-org-silver">{name}</div>
          {type === "checkbox" && (
            <Checkbox isChecked={checkboxChecked} onChange={onCheckboxChange} />
          )}
          {(isSort || isFilter) && (
            <div className="ml-auto flex justify-end items-center flex-shrink-0">
              {isSort && <TableSort sortState={"nostate"} onSort={onSort} />}
              {isFilter && dropdownData?.length > 0 && (
                <TableFilter
                  xPlacement={"right"}
                  filterData={dropdownData}
                  filterSearch={filterSearch}
                  onFilter={onFilter}
                />
              )}
            </div>
          )}
        </div>
      </th>
    </>
  );
};

export default Th;
