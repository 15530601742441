import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NumberCountAnimation from "../../../helpers/NumberCountAnimation";
import DashboardSkeleton from "../../../components/skeleton/DashboardSkeleton";
import { toast } from "react-toastify";
import { dashboard } from "../../../services/DashboardService";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dashboard()
      .then((res) => {
        if (res && res?.status === 200) {
          setDashboardData(res?.data);
        } else {
          toast.error(res?.message || "Failed to fetch admin data");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred while fetching the dashboard data.");
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className=" bg-transparent  px-6  py-8">
          <div className="flex justify-between items-center mb-6">
            <div className="text-2xl font-bold">Dashboard</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <Link to="/admin/users">
              <div className="relative flex items-center space-x-4 p-4 border border-indigo-500 bg-indigo-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-indigo-400">
                <i className="fa-solid fa-users text-4xl text-indigo-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-indigo-600">
                  Users
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-indigo-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.userCount || 28}
                    duration={1000}
                  />
                </div>
              </div>
            </Link>
            <Link to="/admin/albums">
              <div className="relative flex items-center space-x-4 p-4 border border-pink-500 bg-pink-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-pink-400">
                <i className="fa-duotone fa-image text-4xl text-pink-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-pink-600">
                  Albums
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-pink-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.albumCount || 0}
                    duration={1000}
                  />
                </div>
              </div>
            </Link>
            <Link to="/admin/dashboard">
              <div className="relative flex items-center space-x-4 p-4 border border-cyan-500 bg-cyan-50 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-cyan-400">
                <i className="fa-duotone fa-chart-tree-map text-4xl text-cyan-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-cyan-600">
                  Collections
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-cyan-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.collectionCount || 0}
                    duration={1000}
                  />
                </div>
              </div>
            </Link>
            <Link to="/admin/dashboard">
              <div className="relative flex items-center space-x-4 p-4 border border-green-600 bg-green-100 rounded-lg shadow min-h-[100px] hover:shadow-lg hover:shadow-green-400">
                <i className="fa-solid fa-business-time text-4xl text-green-600"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-green-600">
                  Businesses
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-green-600 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.placeCount || 0}
                    duration={1000}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <DashboardSkeleton />
      )}
    </>
  );
};

export default Dashboard;
