import { apiRequest } from "../util/apiUtils";

export const dashboard = async () => {
    try {
      const res = await apiRequest(
        "get",
        `/dashboard/count`
      );
  
      return res?.data;
    } catch (err) {
      throw err;
    }
  };