export default function PageHeader({ title, headerActions = null }) {
  return (
    <>
      <section className="top-0 w-full z-60 bg-slate-100 px-6 py-3 border-t border-gray-200">
        <div className="flex md:items-center md:flex-row flex-col space-y-4 md:space-y-0">
          <div className="text-sm md:text-xl font-bold text-black my-auto">
            {title}
          </div>
          <div className="ml-auto">{headerActions && headerActions()}</div>
        </div>
      </section>
    </>
  );
}
