import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Pagination = ({
  pageCount = 0,
  onPageChange = () => {},
  currentPage = 1,
  paginationClasses,
}) => {
  const [selectedPage, setSelectedPage] = useState(currentPage);
  const [totalPagesList, setTotalPagesList] = useState([]);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  function generatePaginationList(selectedPage, totalPages) {
    const maxPagesToShow = 7;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const pagesToShow = maxPagesToShow - 2;
      let startPage = Math.max(1, selectedPage - Math.floor(pagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

      if (endPage - startPage < pagesToShow - 1) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
      }

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("...");
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  }

  useEffect(() => {
    setTotalPagesList(generatePaginationList(selectedPage, pageCount));
  }, [selectedPage, pageCount]);

  return (
    <div
      className={classNames(
        "flex items-center justify-end pb-5 px-0 md:px-5",
        paginationClasses
      )}
    >
      <button
        className="flex items-center gap-2 px-4 py-3 font-sans text-xs font-bold text-center text-opplio-blue uppercase align-middle transition-all rounded-full select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button"
        onClick={() => {
          if (selectedPage > 1) {
            const newPage = selectedPage - 1;
            setSelectedPage(newPage);
            onPageChange({ selected: newPage });
          }
        }}
        disabled={selectedPage === 1}
      >
        <i className="fa-solid fa-arrow-left" />
      </button>
      <div className="flex items-center gap-2">
        {totalPagesList &&
          Array.isArray(totalPagesList) &&
          totalPagesList.length > 0 &&
          totalPagesList.map((number, i) => (
            <button
              key={i.toString()}
              onClick={() => {
                if (number === "...") {
                  return;
                } else {
                  setSelectedPage(number);
                  if (typeof onPageChange === "function") {
                    onPageChange({ selected: number });
                  }
                }
              }}
              className={`relative h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${
                number === "..." ? "" : "cursor-pointer "
              } ${
                selectedPage === number
                  ? "bg-black text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                  : "text-opplio-blue hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              }`}
              type="button"
              disabled={number === "..."}
            >
              <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                {number}
              </span>
            </button>
          ))}
      </div>

      <button
        className="flex items-center gap-2 px-4 py-3 font-sans text-xs font-bold text-center text-opplio-blue uppercase align-middle transition-all rounded-full select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button"
        onClick={() => {
          if (selectedPage < pageCount) {
            const newPage = selectedPage + 1;
            setSelectedPage(newPage);
            onPageChange({ selected: newPage });
          }
        }}
        disabled={selectedPage === pageCount}
      >
        <i className="fa-solid fa-arrow-right" />
      </button>
    </div>
  );
};

export default Pagination;
