import React, { useState } from "react";
import Button from "../../components/forms/Button";
import SidebarItem from "./SidebarItem";
import { classNames } from "../../helpers/classNames";

export default function SidebarGroup({ data }) {
  const [isOpen, setIsOpen] = useState(data.isOpen ? true : false);

  return (
    <>
      <div className="relative z-0">
        <Button
          buttonHasLink={false}
          buttonIcon={data.icon}
          buttonIconPosition={"left"}
          buttonLabel={data.name}
          buttonLabelClasses={"!text-sm !font-medium"}
          buttonClasses={classNames(
            "w-full !bg-transparent !border-transparent !rounded-none !px-4 !h-10 !justify-start",
            data.isActive
              ? "!text-victoria !bg-white"
              : "text-white group-hover/parent:!bg-white/20"
          )}
          buttonFunction={() => setIsOpen(!isOpen)}
        />
        <div
          className={classNames(
            "absolute right-4 top-1/2 -translate-y-1/2  text-sm group-hover/parent:text-victoria z-[1] transition-all duration-200 ease-in-out",
            data.isActive
              ? "!text-victoria !bg-white"
              : "text-white group-hover/parent:text-white",
            isOpen ? "rotate-90" : "rotate-0"
          )}
        >
          <i className={classNames("fa-fw fa-solid fa-caret-right")}></i>
        </div>
      </div>
      {data.submenu.length > 0 && (
        <div
          className={classNames(
            "relative  overflow-hidden transition-all duration-200 ease-in-out faq-decription",
            isOpen ? "max-h-[2000px]" : "max-h-0"
          )}
        >
          <div className="py-1 bg-white/5">
            {data.submenu.map((item, index) => (
              <div className="group/link" key={index}>
                <SidebarItem data={item} size="sm" />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
