import React from "react";
import { Navigate } from "react-router-dom";

const FreeAuthRoute = ({ auth, children, title = "" }) => {
  const token = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");

  if (token && role === "ADMIN") {
    return <Navigate to="/admin/dashboard" />;
  } else {
    return <>{children}</>;
  }
};

export default FreeAuthRoute;
