import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import AdminLayout from "./layouts/admin/AdminLayout";
import Admins from "./pages/admin/admins/Admins";
import Collections from "./pages/admin/collections/Collections";
import Places from "./pages/admin/places/Places";
import Pages from "./pages/admin/pages/Pages";
import Emails from "./pages/admin/emails/Emails";
import Settings from "./pages/admin/settings/Settings";
import AdminLogin from "./pages/admin/signIn/AdminLogin";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import PageNotFound from "./pages/admin/PageNotFound";
import AlbumList from "./pages/admin/album/AlbumList";
import AddGallery from "./pages/admin/album/Gallery";
import ManageAlbum from "./pages/admin/album/ManageAlbum";
import UsersList from "./pages/admin/users/UsersList";
import UserDetails from "./pages/admin/users/UserDetails";
import Notification from "./pages/admin/notification/send";
const authRoutes = [
  { path: "/admin/signin", title: "Admin Login", element: <AdminLogin /> },
];

const adminRoutes = [
  { path: "/admin/dashboard", component: <Dashboard /> },
  // { path: "/", element: <Dashboard /> },
  { path: "/admin/users", component: <UsersList /> },
  { path: "/user/details/:id", component: <UserDetails /> },
  { path: "/admin/admins", component: <Admins /> },
  { path: "/admin/collections", component: <Collections /> },
  { path: "/admin/places", component: <Places /> },
  { path: "/album/gallery/:id", component: <AddGallery /> },
  { path: "/album/edit/:id", component: <ManageAlbum /> },
  { path: "/admin/pages", component: <Pages /> },
  { path: "/admin/emails", component: <Emails /> },
  { path: "/admin/settings", component: <Settings /> },
  { path: "/admin/albums", component: <AlbumList /> },
  { path: "/admin/send-notification", component: <Notification /> },
];

export default function CreateRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {authRoutes.map(({ path, title, element }) => (
            <Route
              key={path}
              path={path}
              element={<FreeAuthRoute title={title}>{element}</FreeAuthRoute>}
            />
          ))}
          <Route path="/" element={<Navigate to="/admin/dashboard" />} />
          {adminRoutes.map(({ path, component }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute getAccess={["ADMIN"]}>
                  <AdminLayout />
                </ProtectedRoute>
              }
            >
              <Route
                path={path}
                exact
                element={
                  <ProtectedRoute getAccess={["ADMIN"]}>
                    {component}
                  </ProtectedRoute>
                }
              />
            </Route>
          ))}
          <Route
            path="/admin"
            element={
              <ProtectedRoute getAccess={["ADMIN"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
