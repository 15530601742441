import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import Button from "../forms/Button";

const Modal = ({
  title,
  subtitle,
  children,
  open = false,
  onClose = () => {},
  size = "xl4",
  modaltitleClasses,
  modalDialogueStyle = "",
  modalBodyClass = "",
  headerClass = "",
  header = true,
  centered = true,
  modalClass,
  titleIcon,
  childrenClasses,
}) => {
  const modalSize = {
    xs: "sm:max-w-xs",
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    xl2: "sm:max-w-2xl",
    xl3: "sm:max-w-3xl",
    xl4: "sm:max-w-4xl",
    xl5: "sm:max-w-5xl",
    xl6: "sm:max-w-6xl",
    xl7: "sm:max-w-7xl",
    xl8: "sm:max-w-8xl",
    xl9: "sm:max-w-9xl",
    full: "max-w-full",
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gradient-to-b from-black/80 via-black/40 to-black/80" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={classNames(
                "flex min-h-full justify-center p-4 text-center",
                centered ? "items-center" : "items-start",
                modalClass
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    `w-full ${modalSize[size]} transform text-left align-middle shadow-xl transition-all`,
                    modalBodyClass
                  )}
                >
                  <div
                    className={classNames(
                      "rounded-2xl bg-white ",
                      modalDialogueStyle
                    )}
                  >
                    {header && (
                      <div
                        className={classNames(
                          "relative  flex py-4 px-4 justify-between border-b border-slate-200",
                          headerClass
                        )}
                      >
                        <div className="mr-auto flex-shrink">
                          <h2
                            className={classNames(
                              "text-2xl tracking-tight font-semibold text-slate-800",
                              modaltitleClasses
                            )}
                          >
                            {titleIcon && (
                              <i
                                className={`${titleIcon} p-2 text-slate-400`}
                              ></i>
                            )}

                            {title}
                          </h2>
                          {subtitle && (
                            <div className="text-sm font-medium text-slate-400">
                              {subtitle}
                            </div>
                          )}
                        </div>
                        <div className="ml-auto flex-shrink-0">
                          <Button
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonClasses={
                              "!w-8 !h-8 !p-0 !justify-center !text-xl !bg-transparent !border-transparent !text-slate-400 hover:!bg-slate-100 hover:rotate-90 hover:!text-slate-400"
                            }
                            buttonFunction={onClose}
                          />
                        </div>
                      </div>
                    )}
                    <div className={`relative py-4 px-4 ${childrenClasses}`}>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
