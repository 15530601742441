import { useState, Fragment, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { getUserList } from "../../../services/UserService";
import { toast } from "react-toastify";
import { apiRequest } from "../../../util/apiUtils";
import { useDebounce } from "../../../hooks/useDebounce";
const users = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Alice Johnson" },
    { id: 4, name: "Bob Brown" },
];


export default function Send() {

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [search, setSearch] = useState("");
    const [content, setContent] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUser] = useState([]);
    const [laoder, setLoader] = useState(false);
    const [allUsers, setAllUsers] = useState(false);
    const dropdownRef = useRef(null);

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        const getList = () => {
            const payload = {
                sortQuery: '-createdAt',
                keyword: debouncedSearch,
                limit: 100,
                page: 1,
                emailVerified: 1,
                status: 1
            };
            getUserList(payload).then((res) => {
                if (res && res?.docs?.success) {
                    setUser(res.docs?.data)
                } else {

                }
            });
        }
        getList();
    }, [debouncedSearch]);

    const send = async () => {
        setLoader(true)
        try {
            const res = await apiRequest("post", `notification/add`, {
                body: {
                    "collaborators": selectedUsers,
                    "message": content,
                    "forAll": allUsers ? 2 : 1
                },
            });

            if (res.status === 200) {
                toast.success(res?.data?.message)
                setLoader(false)
            }
        } catch (error) {
            // console.log(error,'error')
            toast.error(error?.data?.message);
            setLoader(false)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-2xl p-8 space-y-4 bg-white shadow-lg rounded-2xl">
                <h2 className="text-2xl font-semibold mb-8">Send Message</h2>
                <div className="flex items-center space-x-2 mb-4">
                    <input
                        type="checkbox"
                        id="allUsers"
                        checked={allUsers}
                        onChange={(e) => setAllUsers(e.target.checked)}
                        className="hidden"
                    />
                    <label htmlFor="allUsers" className="relative flex items-center cursor-pointer select-none">
                        <div className="w-6 h-6 border rounded-md flex items-center justify-center bg-white shadow">
                            {allUsers && (
                                <svg className="w-4 h-4 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            )}
                        </div>
                        <span className="ml-2 text-sm font-medium">ALL USERS</span>
                    </label>
                </div>
                {!allUsers && <Listbox value={selectedUsers} onChange={setSelectedUsers} multiple>
                    <div className="relative" ref={dropdownRef}>
                        <Listbox.Button
                            className="w-full flex justify-between items-center border p-3 rounded-lg focus:ring focus:ring-blue-300 bg-white"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {selectedUsers.length > 0 ? `${selectedUsers.length} Users Selected` : "Select Users"}
                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path></svg>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            show={isOpen}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <div className="absolute w-full bg-white shadow-lg rounded-lg mt-2 z-10 border">
                                <div className="p-2">
                                    <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="w-full p-2 border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                                <Listbox.Options className="max-h-40 overflow-auto">
                                    {users.length > 0 ? (
                                        users.map((user) => (
                                            <Listbox.Option
                                                key={user._id}
                                                value={user._id}
                                                className={({ active }) => `p-2 cursor-pointer flex items-center space-x-2 ${active ? 'bg-blue-100' : ''}`}
                                                onClick={() => setIsOpen(true)}
                                            >
                                                <div className="w-5 h-5 border rounded flex items-center justify-center bg-white shadow">
                                                    {selectedUsers.includes(user._id) && (
                                                        <svg className="w-4 h-4 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <polyline points="20 6 9 17 4 12"></polyline>
                                                        </svg>
                                                    )}
                                                </div>
                                                <span>{user.firstName} {user.lastName} ({user.email})</span>
                                            </Listbox.Option>
                                        ))
                                    ) : (
                                        <div className="p-2 text-gray-500 text-center">No users found</div>
                                    )}
                                </Listbox.Options>
                            </div>
                        </Transition>
                    </div>
                </Listbox>}
                <textarea placeholder="Type your message here..." style={{ height: '100px' }} className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300" onChange={(e) => { setContent(e.target.value) }}></textarea>
                <button className="w-full bg-black text-white p-3 rounded-lg " disabled={laoder} onClick={() => {
                    send()
                }}>{laoder ? 'Sending...' : 'Send'}</button>
            </div>
        </div>
    );
}
