import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Indentification from "../../../components/elements/Indentification";
import { initialsValue } from "../../../helpers";
import Tabs from "../../../components/elements/Tabs";
import Button from "../../../components/forms/Button";
import UserDetailsDetailsSkeleton from "../../../components/skeleton/CompanyDetailsSkeleton";
import { profileDetails } from "../../../services/UserService";
import { toast } from "react-toastify";

const UserDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [selectedTabId, setSelectedTabId] = useState();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      setLoading(true);
      profileDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails(res?.data);
          setLoading(false);
        } else {
          toast.error("Something went wrong!");
          setLoading(false);
        }
      });
    }
  }, [id]);
  console.log(details);

  const tabData = [
    {
      _id: 1,
      name: "Collection List",
      //   component: <CompanyEmployee id={id} />,
    },
    {
      _id: 2,
      name: "Company Departments",
      //   component: <CompanyDepartment id={id} />,
    },
    {
      _id: 3,
      name: "Company Positions",
      //   component: <CompanyPosition id={id} />,
    },
    {
      _id: 4,
      name: "Company Calender",
      //   component: <CompanyEvents id={id} />,
    },
  ];
  useEffect(() => {
    if (location?.state === "department") {
      setSelectedTabId(2);
    } else if (location?.state === "position") {
      setSelectedTabId(3);
    } else if (location?.state === "events") {
      setSelectedTabId(4);
    } else {
      setSelectedTabId(1);
    }
  }, [location?.state]);

  return (
    <>
      {!loading ? (
        <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
          <div className="flex items-center justify-between pb-4">
            <div className="font-semibold text-lg md:text-xl xl:text-2xl">
              User Details
            </div>
          </div>
          <div className="w-full xl:flex block xl:space-y-0 space-y-6 gap-6 mt-6 items-center ">
            <div className="w-full md:flex block lg:space-x-10 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow-md border border-slate-300 items-start">
              <div className="flex justify-center">
                <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-md flex-shrink-0">
                  <Indentification
                    initial={initialsValue(details?.name)}
                    image={details?.image}
                    alt={details.data}
                    fill={true}
                    className="object-cover h-full w-full"
                    size="max"
                    initialClass="!text-7xl"
                  />
                </div>
              </div>
              <div className="flex-shrink flex-grow md:mt-0 mt-2">
                <div className="md:flex block justify-between md:space-y-0 space-y-3">
                  <div className="flex gap-4 items-center lg:justify-start justify-center">
                    <div className="text-chocolate font-bold md:text-3xl text-2xl">
                      {details?.firstName + " " + details?.lastName}
                    </div>
                  </div>
                  {/* <div className="bg-red-100 px-4 py-1 rounded-lg text-red-600 font-bold text-xl text-center">
                    Orders : {orderList?.data?.length || "0"}
                  </div> */}
                </div>
                <div className=" block 3xl:space-y-4 space-y-2 mt-8 items-center">
                  <div className="3xl:flex block gap-4">
                    <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2">
                      <div className="md:text-xl text-xl text-chocolate pr-2">
                        <i class="fa-solid fa-envelope"></i>
                      </div>
                      <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">
                          Email Address:-
                        </div>
                        <div className="md:text-lg text-sm">
                          {" "}
                          {details?.email}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2 3xl:mt-0 mt-2">
                      <div className="md:text-xl text-xl text-chocolate pr-2">
                        <i class="fa-solid fa-phone"></i>
                      </div>
                      <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">
                          Phone Number:-
                        </div>
                        <div className="md:text-lg text-sm">
                          {details?.phone || ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2 items-center">
                    <div className="md:text-xl text-xl text-chocolate pr-2">
                    <i class="fa-solid fa-mobile"></i>
                    </div>
                    <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">
                          Platform:-
                        </div>
                        <div className="md:text-lg text-sm">
                          {details?.platform || ""}
                        </div>
                      </div>
                  </div>
                  <div className="w-full flex 2xl:gap-4 xl:gap-1 gap-2 items-center">
                    <div className="md:text-xl text-xl text-chocolate pr-2">
                    <i class="fa-duotone fa-solid fa-signal-bars"></i>
                    </div>
                    <div className="md:flex block 2xl:gap-4 xl:gap-1 gap-2">
                        <div className="md:text-lg text-sm font-semibold">
                          Status:-
                        </div>
                        <div className="md:text-lg text-sm">
                          {details?.status || ""}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mt-2">
            <Tabs
              tabs={tabData}
              selectedTabId={selectedTabId}
              onTabChange={(tabId) => setSelectedTabId(tabId)}
            />
          </div>
        </div>
      ) : (
        <UserDetailsDetailsSkeleton />
      )}
    </>
  );
};

export default UserDetails;
