import React from "react";
import { classNames } from "../../helpers/classNames";

const Search = ({
  placeholder,
  classes,
  divClasses,
  setSelectedMember = () => {},
  iconClasses,
  icon = "fa-regular fa-fw fa-magnifying-glass",
  searchValue = "",
  setInput,
  onKeyDown = () => {},
  valueReset = () => {},
}) => {
  return (
    <>
      <div
        className={classNames(
          "relative flex h-10 w-full bg-white rounded-md border border-slate-200",
          divClasses
        )}
      >
        <div className={classNames("w-10 h-10 flex items-center justify-center text-slate-400 ", iconClasses)}>
          <i className={classNames("fa-fw", icon)}></i>
        </div>
        <input
          type="text"
          placeholder={placeholder}
          className={
            "!border-0 !border-slate-200 !ring-0 bg-transparent text-slate-600 text-sm px-3 focus:outline-none focus:border-transparent" +
            classes
          }
          //value={searchValue}
          onChange={(e) => {
            setInput(e.target.value);
            setSelectedMember([]);
            valueReset();
          }}
          onKeyDown={onKeyDown}
        />
      </div>
    </>
  );
};

export default Search;
