import { apiRequest } from "../util/apiUtils";

export const getUserList = async (params) => {
    try {
      const res = await apiRequest("get", "user/list", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  export const changeUserStatus = async (payload = {}) => {
    try {
      const res = await apiRequest("patch", `user/status`, {
        body: payload,
      });
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const changeUserEmailStatus = async (payload = {}) => {
    try {
      const res = await apiRequest("patch", `user/status`, {
        body: payload,
      });
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const manageUser = async (params) => {
    try {
      const res = await apiRequest(
        `${params.id ? "put" : "post"}`,
        `user/${params?.id ? `edit` : "add"}`,
        {
          body: params,
        },
        "multipart/form-data"
      );
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  export const profileDetails = async (params) => {
    try {
      const res = await apiRequest("get", `profile?userId=${params?.id}`);
      return res?.data;
    } catch (error) {
      return error;
    }
  };
