import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/forms/Button";
import Input from "../../../components/forms/Input";
import ImageUploadSingle from "../../../components/forms/ImageUploadSingle";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/shared/Modal";
import { useSelector } from "react-redux";
import { deleteImage, profileUpdate } from "../../../services/ProfileService";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";

const Profife = ({ profileModal, setProfileModal = () => {} }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.profile?.data);
  const [selectedImg, setSelectedImg] = useState();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    loading: false,
    data: {},
  });
  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const getDetails = useCallback(() => {
    if (profile) {
      setFieldsValue({
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        email: profile?.email || "",
      });
      setSelectedImg(profile?.image || "");
    } else {
      setDetails({ loading: false, data: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const handleRemoveFile = () => {
    const userId = profile?.id;
    if (!(selectedImg instanceof File)) {
      deleteImage({ userId })
        .then((res) => {
          console.log("Image deleted successfully:", res);
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
    } else {
      console.log("Selected item is a file, cannot delete.");
    }
  };

  const onSubmit = (values) => {
    setLoading(true);
    profileUpdate({
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      image: selectedImg || "null",
    }).then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
        setProfileModal({ isOpen: false });
        toast.success(res?.message);
        setLoading(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <div>
        <Modal
          title={"Profile"}
          size="xl3"
          open={profileModal.isOpen}
          onClose={() => {
            setProfileModal({ isOpen: false, data: "" });
          }}
          modaltitleClasses={"!text-slate-200 text-lg"}
          headerClass={"!bg-black !rounded-t-2xl !text-white"}
        >
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <div className="grid grid-cols-12 gap-4 divide-x divide-slate-200">
              {/* Profile Picture and Name Section */}
              <div className="col-span-12 lg:col-span-4">
                <div className="text-lg font-semibold text-slate-700 text-center mb-2 w-full">
                  <div className="text-lg font-semibold text-slate-700">
                    {/* {profile?.firstName + " " + profile?.lastName} */}
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <ImageUploadSingle
                    image={selectedImg}
                    setImage={setSelectedImg}
                    icon={"fa-regular fa-image"}
                    title={"Choose a file or drag & drop it here"}
                    isDirectUpload={true}
                    removeImage={handleRemoveFile}
                  />
                </div>
              </div>

              {/* Profile Info Form Section */}
              <div className="col-span-12 lg:col-span-8 px-4">
                <div className="text-lg font-semibold text-slate-700">
                  Profile Info
                </div>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="relative">
                    <Input
                      label={"First Name"}
                      inputType={"text"}
                      value={values?.firstName}
                      inputPlaceholder={"Enter last name..."}
                      inputName="firstName"
                      onChange={handleChange}
                      isValidate={true}
                      charLimit={20}
                      {...formValidate(errors, "firstName")}
                    />
                  </div>

                  <div className="relative">
                    <Input
                      label={"Last Name"}
                      inputType={"text"}
                      value={values?.lastName}
                      inputPlaceholder={"Enter last name..."}
                      inputName="lastName"
                      onChange={handleChange}
                      isValidate={true}
                      charLimit={20}
                      {...formValidate(errors, "lastName")}
                    />
                  </div>

                  <div className="col-span-2 relative">
                    <Input
                      label={"Email"}
                      inputType={"email"}
                      value={values?.email}
                      inputPlaceholder={"Enter email..."}
                      inputName="email"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "email")}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-4 gap-4">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !border-black !text-black"}
                buttonFunction={() => {
                  setProfileModal({ isOpen: false, data: "" });
                }}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonLabel={"Save"}
                isDisable={loading || details?.loading}
                loading={loading || details?.loading}
              />
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default Profife;
