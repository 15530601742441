import Indentification from "../../components/elements/Indentification";
import LogoutModal from "../../components/shared/LogoutModal";
import { classNames } from "../../helpers/classNames";
import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import Profife from "../../pages/admin/profile/Profile";
import { useSelector } from "react-redux";
import { initialsValue } from "../../helpers";
import ChangePassword from "../../pages/admin/profile/ChangePassword";

// eslint-disable-next-line import/no-anonymous-default-export
const UserDropdown = ({ xPlacement, ...props }) => {
  const popperElRef = useRef(null);
  const profile = useSelector((state) => state?.profile?.data);
  const fullName = `${profile?.firstName || ""} ${profile?.lastName || ""}`;
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [profileModal, setProfileModal] = useState({
    isOpen: false,
  });
  const [passwordModal, setPasswordModal] = useState({ isOpen: false });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false });

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
  });

  const dropdownData = [
    {
      _id: 1,
      label: "Profile",
      icon: "fa-regular fa-circle-user",
      standout: false,
      onClick: () => setProfileModal({ isOpen: true }),
    },
    {
      _id: 2,
      label: "Change Password",
      icon: "fa-regular fa-circle-user",
      standout: false,
      onClick: () => setPasswordModal({ isOpen: true }),
    },
    {
      _id: 3,
      label: "Logout",
      icon: "fa-arrow-right-from-arc",
      standout: true,
      onClick: () => setDeleteModal({ isOpen: true }),
    },
  ];

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <MenuButton ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <Indentification
                  initial={initialsValue(fullName)}
                  image={profile?.image}
                  alt={profile?.firstName}
                  fill={true}
                  className={""}
                  size={"sm"}
                />

                <div className="text-sm font-semibold text-victoria mx-2">
                  {(profile?.firstName || "") + " " + (profile?.lastName || "")}
                </div>

                <div className="text-xs text-slate-600">
                  <i
                    className={classNames(
                      "fa-regular fa-fw fa-chevron-down transition-all duration-200",
                      open ? "-rotate-180" : ""
                    )}
                  ></i>
                </div>
              </div>
            </MenuButton>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <MenuItems className="w-48 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="relative py-1 max-h-96 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar z-90">
                  {dropdownData.map((item) => (
                    <MenuItem key={item._id}>
                      {item?.link ? (
                        <Link
                          href={`${item?.link}`}
                          className={classNames(
                            "flex items-center py-2 px-3 gap-3 font-medium transition-all duration-200",
                            item.standout
                              ? "text-rose-500 hover:bg-rose-500 hover:text-rose-100"
                              : "text-black hover:bg-black hover:text-white"
                          )}
                        >
                          <div className="w-5 h-5 flex items-center justify-center text-base">
                            <i
                              className={classNames(
                                "fa-regular fa-fw",
                                item.icon
                              )}
                            ></i>
                          </div>
                          <div className="text-xs">{item.label}</div>
                        </Link>
                      ) : (
                        <div
                          onClick={() => item?.onClick(item)}
                          className={classNames(
                            "cursor-pointer flex items-center py-2 px-3 gap-3 font-medium transition-all duration-200",
                            item.standout
                              ? "text-rose-500 hover:bg-rose-500 hover:text-rose-100"
                              : "text-black hover:bg-black hover:text-white"
                          )}
                        >
                          <div className="w-5 h-5 flex items-center justify-center text-base">
                            <i
                              className={classNames(
                                "fa-regular fa-fw",
                                item.icon
                              )}
                            ></i>
                          </div>
                          <div className="text-xs">{item.label}</div>
                        </div>
                      )}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>
          </>
        )}
      </Menu>
      <Profife profileModal={profileModal} setProfileModal={setProfileModal} />
      <ChangePassword
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
      />
      <LogoutModal
        open={deleteModal?.isOpen}
        onClose={() => setDeleteModal({ isOpen: false })}
      />
    </>
  );
};
export default UserDropdown;
