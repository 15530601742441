import React from "react";
import img404 from "../../assets/images/404.png";
import Button from "../../components/forms/Button";

const PageNotFound = () => {
  return (
    <div className="flex h-screen">
      <div className="w-full flex items-center justify-end">
        <div className="relative w-[600px] h-[600px]">
          <img
            width={800}
            height={800}
            src={img404}
            alt="invalid"
            className="w-full h-full object-contain object-center"
          />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center">
        <div className="-ml-20 mt-20 relative">
          <div className="text-3xl font-bold text-black">
            It&apos;s seems you&apos;ve taken a wrong turn
          </div>
          <div className="font-medium my-3">
            the page you&apos;re looking for might have been misplaced,
            <br />
            lost in cyperspace, or it simply doesn&apos;t exiist
          </div>
          <div className="absolute -bottom-60 right-[300px] text-[200px] text-black font-extrabold">
            404
          </div>
          <Button
            buttonHasLink={true}
            buttonLink={"/"}
            buttonClasses={
              "!w-fit !bg-black !border-black !text-white"
            }
            buttonLabel={"Go to home page"}
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
