import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/forms/Button";
import Input from "../../../components/forms/Input";
import ImageUploadSingle from "../../../components/forms/ImageUploadSingle";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/shared/Modal";
import { deleteImage } from "../../../services/ProfileService";
import { manageUser } from "../../../services/UserService";

const ManageUser = ({ userModal, setUserModal = () => {}, setList = () => {} }) => {
  const id = userModal?.data?._id;
  const [selectedImg, setSelectedImg] = useState();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    loading: false,
    data: {},
  });
  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    // lastName: { required: true, message: "Please enter your last name!" },
    code: { required: true, message: "Please enter login code" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const getDetails = useCallback(() => {
    if (userModal?.data) {
      setFieldsValue({
        firstName: userModal?.data?.firstName || "",
        lastName: userModal?.data?.lastName || "",
        email: userModal?.data?.email || "",
        code: userModal?.data?.code || "",
      });
      setSelectedImg(userModal?.data?.image || "");
    } else {
      setDetails({ loading: false, data: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModal?.data]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const handleRemoveFile = () => {
    const userId = userModal?.data?._id;
    if (!(selectedImg instanceof File)) {
      deleteImage({ userId })
        .then((res) => {
          console.log("Image deleted successfully:", res);
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
    } else {
      console.log("Selected item is a file, cannot delete.");
    }
  };
  const validateCode = (code) => {
    const errors = [];
    if (code?.length < 6) {
      errors.push("Code must contain at least 6 characters long.");
    } else if (code?.length > 6) {
      errors.push("Code contain only 6 characters.");
    }
    return errors;
  };
  const onSubmit = (values) => {
    setLoading(true);
    if (values.code) {
      const codeErrors = validateCode(values.code);
      if (codeErrors.length > 0) {
        toast.error(codeErrors.join(" "));
        setLoading(false);
        return;
      }
    }
    manageUser({
      id: id,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      code: values?.code,
      file: selectedImg || "null",
    }).then((res) => {
      if (res?.status === 200) {
        setList((prev) => {
          const updatedData = prev.data.map((item) => (item._id === res.data._id ? { ...item, ...res.data } : item));

          return {
            ...prev,
            data: updatedData,
            loading: false,
          };
        });
        setUserModal({ isOpen: false });
        toast.success(res?.message);
        setLoading(false);
      } else {
        toast.error(res?.data?.message);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <div>
        <Modal
          title={"User Details"}
          size="xl3"
          open={userModal.isOpen}
          onClose={() => {
            setUserModal({ isOpen: false, data: "" });
          }}
          modaltitleClasses={"!text-slate-200 text-lg"}
          headerClass={"!bg-black !rounded-t-2xl !text-white"}
        >
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <div className="grid grid-cols-12 gap-4 divide-x divide-slate-200">
              {/* Profile Picture and Name Section */}
              <div className="col-span-12 lg:col-span-4">
                <div className="text-lg font-semibold text-slate-700 text-center mb-2 w-full">
                  <div className="text-lg font-semibold text-slate-700">
                    {/* {profile?.firstName + " " + profile?.lastName} */}
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <ImageUploadSingle
                    image={selectedImg}
                    setImage={setSelectedImg}
                    icon={"fa-regular fa-image"}
                    title={"Choose a file or drag & drop it here"}
                    isDirectUpload={true}
                    removeImage={handleRemoveFile}
                  />
                </div>
              </div>

              {/* Profile Info Form Section */}
              <div className="col-span-12 lg:col-span-8 px-4">
                <div className="text-lg font-semibold text-slate-700">User Info</div>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="relative">
                    <Input
                      label={"First Name"}
                      inputType={"text"}
                      value={values?.firstName}
                      inputPlaceholder={"Enter last name..."}
                      inputName="firstName"
                      onChange={handleChange}
                      isValidate={true}
                      charLimit={20}
                      {...formValidate(errors, "firstName")}
                    />
                  </div>

                  <div className="relative">
                    <Input
                      label={"Last Name"}
                      inputType={"text"}
                      value={values?.lastName}
                      inputPlaceholder={"Enter last name..."}
                      inputName="lastName"
                      onChange={handleChange}
                      isValidate={false}
                      charLimit={20}
                      {...formValidate(errors, "lastName")}
                    />
                  </div>

                  <div className="relative">
                    <Input
                      label={"Email"}
                      inputType={"email"}
                      value={values?.email}
                      inputPlaceholder={"Enter email..."}
                      inputName="email"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "email")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"Code"}
                      inputType={"number"}
                      value={values?.code}
                      inputPlaceholder={"Enter code..."}
                      inputName="code"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "code")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-4 gap-4">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !border-black !text-black"}
                buttonFunction={() => {
                  setUserModal({ isOpen: false, data: "" });
                }}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonLabel={"Save"}
                isDisable={loading || details?.loading}
                loading={loading || details?.loading}
              />
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ManageUser;
