import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "../../../components/elements/table/Table";
import { useDebounce } from "../../../helpers/useDebounce";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  changeUserEmailStatus,
  changeUserStatus,
  getUserList,
} from "../../../services/UserService";
import Select from "../../../components/forms/Select";
import PageHeader from "../../../layouts/shared/PageHeader";
import Search from "../../../components/forms/Search";
import {
  email_filter,
  status_filter,
} from "../../../components/constant/constant";
import Button from "../../../components/forms/Button";
import ManageUser from "./ManageUser";

const AdminList = () => {
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState([]);
  const [emailVerified, setEmailVerified] = useState([]);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [userModal, setUserModal] = useState({
    isOpen: false,
    data: "",
  });
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 300);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [emailFilterData, setEmailFilterData] = useState(email_filter || []);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });

  const data = [
    { name: "All Items", value: "All" },
    { name: "5 Items", value: 5 },
    { name: "10 Items", value: 10 },
    { name: "20 Items", value: 20 },
    { name: "30 Items", value: 30 },
    { name: "50 Items", value: 50 },
    { name: "100 Items", value: 100 },
  ];

  const handleChange = (value) => {
    if (value?.value === "All") {
      setOffset(0);
      setLimit({ name: "All Items", value: count });
      setCurrentPage(1);
      setSelectedItems([]);
    } else {
      setOffset(0);
      setLimit(value);
      setSelectedItems([]);
    }
  };

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const status_filter = {};
    if (status && Array.isArray(status) && status.length > 0) {
      status.forEach((item, i) => (status_filter[`status[${i}]`] = item));
    }
    const email_filter = {};
    if (
      emailVerified &&
      Array.isArray(emailVerified) &&
      emailVerified.length > 0
    ) {
      emailVerified.forEach(
        (item, i) => (email_filter[`emailVerified[${i}]`] = item)
      );
    }
    const payload = {
      sortQuery: sort,
      keyword: search,
      limit: limit?.value,
      page: currentPage,
      ...status_filter,
      ...email_filter,
    };
    getUserList(payload).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, status, emailVerified, sort, search, currentPage]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const getDeleteResponse = (res) => {
    if (res?.status === 200) {
      setList((prev) => {
        return {
          ...prev,
          data: prev.data.filter((item) => item._id !== res?.data?.data?._id),
          loading: false,
        };
      });
      setDeleteModal({ isOpen: false });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };

  //Table Heading Data >>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Name",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 3,
      name: "Code",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 4,
      name: "Email Verified",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: emailFilterData,
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setEmailVerified(data?.map((item) => item?.value));
        } else {
          setEmailVerified([]);
        }
        setCurrentPage(1);
      },
    },
    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: statusFilterData,
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setStatus(data?.map((item) => item?.value));
        } else {
          setStatus([]);
        }
        setCurrentPage(1);
      },
    },
    {
      _id: 6,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Status Change Api Call >>>>>>>>>>

  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem?.status === 1 ? 2 : 1;
    const res = await changeUserStatus({
      ids: [glossaryItem?._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, status: newStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };
  const handleemailStatusChange = async (glossaryItem) => {
    const newEmailStatus = glossaryItem?.emailVerified === 1 ? 2 : 1;
    const res = await changeUserEmailStatus({
      ids: [glossaryItem?._id],
      emailVerified: newEmailStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, emailVerified: newEmailStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: 1,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.firstName ? `${item?.firstName} ${item?.lastName || ""}` : "N/A",
        image: item?.image,
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.code || "N/A",
      },
      {
        _id: 4,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.emailVerified === 1 ? "Verified" : "Not-verified",
        statusType: item?.emailVerified === 1 ? "success" : "danger",
        functions: () => handleemailStatusChange(item),
      },
      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            standout: false,
            onClick: () => {
              setUserModal({
                isOpen: true,
                data: item,
              });
            },
          },
          {
            _id: 2,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () => {
              setDeleteModal({
                isOpen: true,
                apiEndpoint: `user/delete-all`,
                method: "patch",
                data: item,
                payload: {
                  body: {
                    id: item?._id,
                  },
                },
              });
            },
          },
        ],
      },
    ],
  }));

  return (
    <>
      <PageHeader
        title={"Users"}
        headerActions={() => (
          <Button
            buttonHasLink={false}
            buttonType={"button"}
            buttonLabelClasses={"text-xs md:!text-base"}
            buttonClasses={"!px-4 md:!px-7 !bg-transparent !border-transparent"}
            buttonLabel={""}
          />
        )}
      />
      <div className="w-full py-4 px-6">
        <div className="mb-6 flex gap-3">
          <div className="w-40">
            <Select
              dropdownData={data}
              placeholder={"Select Limit"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              selectedValue={limit?.value}
              onChange={handleChange}
            />
          </div>
          <div className="relative md:ml-auto flex justify-end items-center space-y-2 md:gap-3">
            <div className="relative w-full">
              <Search
                placeholder={"search"}
                setInput={setKeyword}
                valueReset={() => {
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
        </div>
        <div className="-mx-8">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6 min-h-[calc(100vh-136px)]"}
            isLoder={list?.loading}
            pageCount={list?.pageCount}
            currentPage={currentPage}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedItems([]);
            }}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
            tableActionDropdownContainer={"!w-48"}
          />
        </div>
      </div>
      <ManageUser
        userModal={userModal}
        setUserModal={setUserModal}
        setList={setList}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        data={{
          apiEndpoint: deleteModal?.apiEndpoint,
          method: deleteModal?.method,
          data: deleteModal?.data,
          payload: deleteModal?.payload,
        }}
        count={count}
        setCount={setCount}
        onClose={() => setDeleteModal({ isOpen: false })}
        getResponse={getDeleteResponse}
      />
    </>
  );
};

export default AdminList;
