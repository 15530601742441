import React, { useEffect, useRef } from "react";
import SidebarBlock from "./SidebarBlock";
import { useSelector } from "react-redux";
import LOGO from "../../assets/images/whitelogo.fa7cdfb8.svg";
import Image from "../../components/elements/Image";
import Button from "../../components/forms/Button";
import { Link } from "react-router-dom";

export default function AdminSidebar({ isMenuOpen, setMenuOpen }) {
  const settings = useSelector((state) => state?.siteSetting?.data);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("sidebarScrollPosition");
    if (savedScrollPosition && sidebarRef.current) {
      sidebarRef.current.scrollTop = savedScrollPosition;
    }

    const handleBeforeUnload = () => {
      if (sidebarRef.current) {
        sessionStorage.setItem(
          "sidebarScrollPosition",
          sidebarRef.current.scrollTop
        );
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClick = () => {
    if (sidebarRef.current) {
      sessionStorage.setItem(
        "sidebarScrollPosition",
        sidebarRef.current.scrollTop
      );
    }
  };

  const navigationMenu = [
    {
      _id: 1,
      name: "Dashboard",
      icon: "fa-duotone fa-gauge",
      link: "/admin/dashboard",
      isActive: false,
      isSub: false,
      isOpen: false,
      submenu: [],
    },
    {
      _id: 2,
      name: "Users",
      icon: "fa-duotone fa-users",
      link: "/admin/users",
      isActive: false,
      isSub: false,
      isOpen: true,
      submenu: [],
    },
    // {
    //   _id: 3,
    //   name: "Admins",
    //   icon: "fa-duotone fa-user-shield",
    //   link: "/admin/admins",
    //   isActive: false,
    //   isSub: false,
    //   isOpen: true,
    //   submenu: [],
    // },
    {
      _id: 4,
      name: "Albums",
      icon: "fa-duotone fa-image",
      link: "/admin/albums",
      isActive: false,
      isSub: false,
      isOpen: false,
      submenu: [],
    },

    {
      _id: 4,
      name: "Push Notification",
      icon: "fa-duotone fa-envelope",
      link: "/admin/send-notification",
      isActive: false,
      isSub: false,
      isOpen: false,
      submenu: [],
    },

    // {
    //   _id: 5,
    //   name: "Gallery",
    //   icon: "fa-duotone fa-image",
    //   link: "/admin/gallery",
    //   isActive: false,
    //   isSub: false,
    //   isOpen: false,
    //   submenu: [],
    // },
    // {
    //   _id: 6,
    //   name: "Collections",
    //   icon: "fa-duotone fa-chart-tree-map",
    //   link: "/admin/collections",
    //   isActive: false,
    //   isSub: false,
    //   isOpen: true,
    //   submenu: [],
    // },
    // {
    //   _id: 7,
    //   name: "Places",
    //   icon: "fa-duotone fa-map-location-dot",
    //   link: "/admin/places",
    //   isActive: false,
    //   isSub: false,
    //   isOpen: true,
    //   submenu: [],
    // },

    // {
    //   _id: 8,
    //   name: "CMS",
    //   icon: "fa-duotone fa-file-lines",
    //   link: "/",
    //   isActive: false,
    //   isSub: true,
    //   isOpen: true,
    //   submenu: [
    //     {
    //       _id: "8-1",
    //       name: "Pages",
    //       icon: "fa-duotone fa-file-lines",
    //       link: "/admin/pages",
    //       isActive: false,
    //     },
    //     {
    //       _id: "8-2",
    //       name: "Email Templates",
    //       icon: "fa-duotone fa-envelope",
    //       link: "/admin/emails",
    //       isActive: false,
    //     },
    //   ],
    // },
    // {
    //   _id: 9,
    //   name: "Settings",
    //   icon: "fa-duotone fa-gear",
    //   link: "/admin/settings",
    //   isActive: false,
    // },
  ];

  return (
    <>
      <aside className="w-full flex flex-col bg-black">
        <div className="relative">
          <div className="relative h-16 flex items-center justify-center py-2 border-b border-white/10 flex-shrink-0 px-3">
            <Link href={"/admin/dashboard"} className="relative flex h-full">
              <Image
                src={LOGO}
                alt={settings?.logo?.url}
                className={"w-full h-full object-contain"}
                isLoader={false}
              />
            </Link>
            <div className="ml-auto md:hidden block">
              <div className="flex items-center gap-4 ">
                <Button
                  buttonHasLink={false}
                  buttonIconPosition={"left"}
                  buttonIcon={
                    "fa-light fa-xmark transform transition-transform duration-300 hover:rotate-180"
                  }
                  buttonClasses={
                    "!px-0 !w-8 !h-8 !rounded-lg !text-xl !bg-white !text-blue-950 !border-transparent !flex !items-center !justify-center hover:!border-slate-200 hover:!shadow-md hover:!bg-white "
                  }
                  buttonFunction={() => setMenuOpen(!isMenuOpen)}
                />
              </div>
            </div>
          </div>
        </div>
        {navigationMenu.length > 0 && (
          <div
            ref={sidebarRef}
            className="py-4 flex-grow overflow-auto scrollbar space-y-1"
            onClick={handleClick}
          >
            {navigationMenu.map((item, i) => (
              <SidebarBlock key={i?.toString()} data={item} />
            ))}
          </div>
        )}
      </aside>
    </>
  );
}
