import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Validation } from "../../../helpers/Validation";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/shared/Modal";
import Button from "../../../components/forms/Button";
import ImageUploadSingle from "../../../components/forms/ImageUploadSingle";
import Input from "../../../components/forms/Input";
import { changePassword } from "../../../services/ProfileService";
import Indentification from "../../../components/elements/Indentification";
import { initialsValue } from "../../../helpers";

const ChangePassword = ({ passwordModal, setPasswordModal = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state?.profile?.data);
  const fullName = `${profile?.firstName} ${profile?.lastName}`;
  const validation = {
    currentPassword: {
      required: true,
      message: "Please enter current password!",
    },
    newPassword: { required: true, message: "Please enter new password!" },
    confirmPassword: {
      required: true,
      message: "Please enter confirm password",
    },
  };

  const { values, handleChange, resetField, handleSubmit, errors } = useForm(
    {},
    validation
  );

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must contain at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("At least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("At least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("At least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("At least one special character (e.g., @, #, $, etc.).");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.currentPassword) {
        const currentPasswordErrors = validatePassword(values.currentPassword);
        if (currentPasswordErrors.length > 0) {
          toast.error(currentPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (values.newPassword) {
        const newPasswordErrors = validatePassword(values.newPassword);
        if (newPasswordErrors.length > 0) {
          toast.error(newPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmPassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password don't match");
        setLoading(false);
        return;
      }

      const res = await changePassword({
        currentPassword: values.currentPassword || "",
        newPassword: values.newPassword || "",
        confirmPassword: values.confirmPassword || "",
      });
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message);
        setPasswordModal({ isOpen: false });
        resetField({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error(res.data?.message || "Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Change Password"
        size="xl"
        open={passwordModal.isOpen}
        onClose={() => {
          setPasswordModal({ isOpen: false });
          resetField({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-2xl !text-white"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="grid grid-cols-12 gap-4 divide-x divide-slate-200">
            {/* Profile Picture and Name Section */}
            <div className="col-span-12 lg:col-span-4">
              <div className="col-span-12 lg:col-span-6">
                <div className="h-48 rounded-md border ">
                  <Indentification
                    initial={initialsValue(fullName)}
                    image={profile?.image}
                    alt={profile?.firstName}
                    fill={true}
                    className="object-cover h-full w-full !rounded-lg"
                    size="max"
                    initialClass="!text-8xl"
                  />
                </div>
              </div>
            </div>

            {/* Profile Info Form Section */}
            <div className="col-span-12 lg:col-span-8 px-4">
              <div className="space-y-2">
                <div className="relative">
                  <Input
                    label={"Current Password"}
                    inputType={"password"}
                    value={values?.currentPassword}
                    inputPlaceholder={"Enter current password..."}
                    inputName="currentPassword"
                    onChange={handleChange}
                    isValidate={true}
                    {...formValidate(errors, "currentPassword")}
                  />
                </div>

                <div className="relative">
                  <Input
                    label={"New Password"}
                    inputType={"password"}
                    value={values?.newPassword}
                    inputPlaceholder={"Enter new password..."}
                    inputName="newPassword"
                    onChange={handleChange}
                    isValidate={true}
                    {...formValidate(errors, "newPassword")}
                  />
                </div>

                <div className="relative">
                  <Input
                    label={"Confirm Password"}
                    inputType={"password"}
                    value={values?.confirmPassword}
                    inputPlaceholder={"Enter confirm password..."}
                    inputName="confirmPassword"
                    onChange={handleChange}
                    isValidate={true}
                    {...formValidate(errors, "confirmPassword")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end mt-4 gap-4">
            <Button
              buttonHasLink={false}
              buttonType={"button"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonLabel={"Cancel"}
              buttonClasses={"!bg-white !border-black !text-black"}
              buttonFunction={() => {
                setPasswordModal({ isOpen: false, data: "" });
              }}
            />
            <Button
              buttonHasLink={false}
              buttonType={"submit"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonLabel={"Save"}
              isDisable={loading}
              loading={loading}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ChangePassword;
