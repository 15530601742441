import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/forms/Button";
import Input from "../../../components/forms/Input";
import ImageUploadSingle from "../../../components/forms/ImageUploadSingle";
import {
  addAlbum,
  albumDetails,
  getAlbumList,
  getCategoryList,
} from "../../../services/AlbumService";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/shared/Modal";
import SelectDropdownSearch from "../../../components/forms/SelectDropdownSearch";
import { useDebounce } from "../../../hooks/useDebounce";
const ManageAlbum = ({
  albumModal,
  setalbumModal = () => {},
  setList = () => {},
}) => {
  const id = albumModal?.data;
  const [details, setDetails] = useState({
    loading: false,
    data: {},
  });
  const validation = {
    title: { required: true, message: "Please enter title!" },
    categoryId:
      details?.data?.type === 3
        ? { required: false }
        : { required: true, message: "Please choose a category." },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const [selectedImg, setSelectedImg] = useState();
  const [categoryList, setCategoryList] = useState({
    loading: false,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [categoryKeyword, setCategoryKeywords] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const keyword = useDebounce(categoryKeyword, 400);
  const [albumList, setAlbumList] = useState([]);
  const [albumKeyword, setAlbumKeyword] = useState("");
  const [albumLimit, setAlbumLimit] = useState(10);

  // Album List
  useEffect(() => {
    getAlbumList({ keyword: albumKeyword, limit: albumLimit }).then((res) => {
      if (res && res.status === 200) {
        setAlbumList(res?.docs);
      }
    });
  }, [albumKeyword, albumLimit]);

  const loadCategoryList = useCallback(() => {
    setCategoryList({ loading: true, data: [] });

    getCategoryList({
      keyword: keyword,
      limit: 100,
      createdType: [1],
      isParent : true
    })
      .then((res) => {
        if (res && res.docs?.status === 200) {
          setCategoryList({
            loading: false,
            data: res?.docs?.data || [],
          });
        } else {
          setCategoryList({ loading: false, data: [] });
        }
      })
      .catch((error) => {
        setCategoryList({ loading: false, data: [] });
      });
  }, [keyword]);

  useEffect(() => loadCategoryList(), [loadCategoryList]);
  const getDetails = useCallback(() => {
    if (id) {
      setDetails({ loading: true, data: {} });
      albumDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails({ loading: false, data: res?.data });
          setFieldsValue({
            title: res?.data?.title,
            categoryId: res?.data?.categoryData?.map((item) => item?._id) || [],
          });
          setSelectedImg(res?.data?.image);
        } else {
          setDetails({ loading: false, data: {} });
          toast.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [id]);

  const onSubmit = (values) => {
    setLoading(true);
    const payload = {
      title: values?.title,
      categories: values?.categoryId,
      type: 2,
      isParent: true,
      id: id || "",
    };

    if (details?.data?.type === 3) {
      payload.file = selectedImg;
      payload.type = 3;
      payload.id = id;
    }

    addAlbum(payload).then((res) => {
      if (res?.status === 201) {
        setalbumModal({ isOpen: false });
        setCategoryKeywords("");
        setList((prev) => {
          return {
            ...prev,
            data: [res.data, ...prev.data],
            loading: false,
          };
        });
        toast.success(res?.message);
        setCategoryKeywords("");
        setLoading(false);
      } else if (res?.status === 200) {
        setList((prev) => {
          const updatedData = prev.data.map((item) =>
            item._id === res.data._id ? { ...item, ...res.data } : item
          );

          return {
            ...prev,
            data: updatedData,
            loading: false,
          };
        });
        setalbumModal({ isOpen: false });
        toast.success(res?.message);
        setLoading(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <div>
        <Modal
          title={id ? "Edit" : "Add"}
          size="xl"
          open={albumModal.isOpen}
          onClose={() => {
            setalbumModal({ isOpen: false, data: "" });
            setCategoryKeywords("");
            setFieldsValue({
              title: "",
            });
            setDetails("");
          }}
          modaltitleClasses={"!text-slate-200 text-lg"}
          headerClass={"!bg-black !rounded-t-2xl !text-white"}
        >
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <div className="grid grid-cols-12 gap-4 divide-x divide-slate-200">
              {details?.data?.type === 3 ? (
                <>
                  <div className="col-span-12 lg:col-span-6">
                    <ImageUploadSingle
                      image={selectedImg}
                      setImage={setSelectedImg}
                      icon={"fa-regular fa-image"}
                      title={"Choose a file or drag & drop it here"}
                      isDirectUpload={true}
                    />
                  </div>
                  <div className="col-span-12 lg:col-span-6 pl-4 py-4">
                    <div className="relative">
                      <Input
                        label={"Title"}
                        inputType={"text"}
                        value={values?.title}
                        inputPlaceholder={"Enter title"}
                        inputName="title"
                        onChange={handleChange}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-12 lg:col-span-12 pl-4">
                  <div className="relative">
                    <Input
                      label={"Title"}
                      inputType={"text"}
                      value={values?.title}
                      inputPlaceholder={"Enter title"}
                      inputName="title"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "title")}
                    />
                  </div>
                  <div className="relative">
                    <SelectDropdownSearch
                      label={"Category"}
                      placeholder={"Choose an category"}
                      isSearch={false}
                      selectName="categoryId"
                      isMultiple={true}
                      loading={categoryList?.loading}
                      value={values?.categoryId}
                      onChange={handleChange}
                      isValidate={true}
                      dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                      {...formValidate(errors, "categoryId")}
                      dropdownData={categoryList?.data?.map(
                        ({ _id, name }) => ({
                          name: name,
                          _id: _id,
                        })
                      )}
                      onSearch={(val) => {
                        setCategoryKeywords(val);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-end mt-4 gap-4">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !border-black !text-black"}
                buttonFunction={() => {
                  setalbumModal({ isOpen: false, data: "" });
                }}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonLabel={"Save"}
                isDisable={loading || details?.loading}
                loading={loading || details?.loading}
              />
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ManageAlbum;
