import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import Button from "../../../components/forms/Button";
import ImageUploadSingle from "../../../components/forms/ImageUploadSingle";
import PageHeader from "../../../layouts/shared/PageHeader";
import {
  addAlbum,
  albumDetails,
  getAlbumList,
  saveAlbumOrdering,
} from "../../../services/AlbumService";
import Image from "../../../components/elements/Image";
import DeleteModal from "../../../components/common/DeleteModal";

const AddGallery = () => {
  const { id } = useParams();
  const [selectedImg, setSelectedImg] = useState();
  const [selectedMember, setSelectedMember] = useState([]);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    loading: false,
    data: {},
  });
  const [albumList, setAlbumList] = useState({ data: [], totalDocs: 0 });
  const [albumKeyword, setAlbumKeyword] = useState("");
  const [albumOffset, setAlbumOffset] = useState(0);
  const [albumCount, setAlbumCount] = useState("");
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [defaultImage, setDefaultImage] = useState([]);

  const getDetails = useCallback(() => {
    if (id) {
      setDetails({ loading: true, data: {} });
      albumDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails({ loading: false, data: res?.data });
          setDefaultImage(res?.data?.image || []);
        } else {
          setDetails({ loading: false, data: {} });
          toast.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // Album List
  // useEffect(() => {
  //   getAlbumList({
  //     keyword: albumKeyword,
  //     limit: 15,
  //     isParent: false,
  //     sortQuery: "ordering",
  //     parentId: id,
  //   }).then((res) => {
  //     if (res && res.docs?.status === 200) {
  //       setAlbumList({
  //         data: res?.docs?.data,
  //         totalDocs: res?.docs?.metadata?.totalDocs,
  //       });
  //     }
  //   });
  // }, [albumKeyword, albumLimit, id]);

  const loadList = useCallback(() => {
    setLoading(true);
    setAlbumList((pre) => ({
      ...pre,
      loading: true,
    }));

    getAlbumList({
      keyword: albumKeyword,
      limit: limit,
      isParent: false,
      sortQuery: "ordering",
      parentId: id,
      page: currentPage,
    }).then((res) => {
      if (res && res.docs?.status === 200) {
        if (currentPage > 1) {
          setAlbumList((prevList) => ({
            ...prevList,
            loading: false,
            data: [...prevList.data, ...(res?.docs?.data || [])],
            // pageCount: res?.docs?.media?.metadata?.totalPages,
            // totalItem: res?.docs?.media?.metadata?.totalDocs,
          }));
        } else {
          setAlbumList({
            loading: false,
            data: res.docs?.data,
            // pageCount: res?.docs?.media?.metadata?.totalPages,
            // totalItem: res?.docs?.media?.metadata?.totalDocs,
          });
        }
        setPagination({
          hasNextPage: res?.docs.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
        setLoading(false);
      } else {
        setAlbumList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
        setLoading(false);
      }
    });
  }, [limit, currentPage]);
  useEffect(() => {
    loadList();
  }, [loadList]);

  const onSubmit = (values) => {
    setLoading(true);
    addAlbum({
      file: values,
      isParent: false,
      parentId: id,
      type: 1,
    }).then((res) => {
      if (res?.status === 201) {
        setSelectedImg();
        clearTimeout();
        setAlbumList((prev) => ({
          data: [...prev.data, res?.data],
          totalDocs: prev.totalDocs + 1,
        }));
        toast.success(res?.message);
        setLoading(false);
      } else if (res?.status === 200) {
        setSelectedImg();
        clearTimeout();
        setAlbumList((prev) => ({
          data: [...prev.data, res?.data],
          totalDocs: prev.totalDocs + 1,
        }));
        toast.success(res?.message);
        setLoading(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    });
  };
  const handleDefault = () => {
    const data = selectedMember?.filter((item) => item?._id);
    addAlbum({
      id,
      image: data?.length > 0 && data[0]?.image,
      type: 2,
    }).then((res) => {
      if (res?.status === 200) {
        setDefaultImage(res?.data?.image || []);
        setSelectedMember([]);

      }
    });
  };
  const handleDeleteClick = () => {
    setDeleteModal({
      isOpen: true,
      apiEndpoint: "gallery/delete-all",
      method: "patch",
      data: selectedMember,
      payload: {
        body: {
          ids: selectedMember?.map((item) => item?._id),
        },
      },
    });
  };

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    apiEndpoint: null,
    method: undefined,
    targetId: null,
    data: {},
    payload: {
      body: {
        //body or queries
        id: [],
      },
    },
  });
  const getDeleteResponse = (res) => {
    if (res?.status === 200) {
      if (Array.isArray(res?.data?.data)) {
        setAlbumList((prev) => {
          const updatedData = prev.data.filter(
            (item) =>
              !res.data.data.some((deletedItem) => deletedItem._id === item._id)
          );
          return {
            ...prev,
            data: updatedData,
            loading: false,
          };
        });
      } else {
        setAlbumList((prev) => {
          const updatedData = prev.data.filter(
            (item) => item._id !== res?.data?.data?._id
          );
          return {
            ...prev,
            data: updatedData,
            loading: false,
          };
        });
      }

      setDeleteModal({ isOpen: false });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };

  const handleOrdering = async (payloadArticles) => {
    const payload = { data: [] };
    payload.data = payloadArticles.map((item, index) => ({
      ...item,
      ordering: index + albumOffset,
      id: item._id,
    }));

    try {
      const orderChangeData = await saveAlbumOrdering(payload);
      if (orderChangeData.status === 200) {
        toast.success(orderChangeData.message);
        setAlbumList((prevList) => ({
          ...prevList,
          data: payload.data,
        }));
      } else {
        toast.error(orderChangeData.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (member) => member?._id === item?._id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  function onScroll({ scrollTop, clientHeight, scrollHeight }) {
    if (
      Math.ceil(scrollTop) >= scrollHeight - clientHeight &&
      pagination?.hasNextPage
    ) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <>
        <div className="flex justify-between gap-4">
          <div className="w-full flex-shrink">
            <div className="relative bg-white  shadow-md   mb-6 space-y-6">
              <PageHeader
                title={
                  id
                    ? `Manage Gallery(${
                        details?.data?.title ? details?.data?.title : "N/A"
                      } Album)`
                    : "Manage Gallery"
                }
              />

              <div className="py-2 px-6">
                <div className="w-full bg-white rounded-md shadow overflow-hidden py-5 px-4">
                  <div className="grid grid-cols-12 gap-4 divide-x divide-slate-200">
                    <div className="col-span-12 lg:col-span-9">
                      <div className="space-y-3">
                        <DragDropContext
                          onDragEnd={(result) => {
                            if (!result.destination) return;
                            const items = Array.from(albumList.data);
                            const [reorderedItem] = items.splice(
                              result.source.index,
                              1
                            );
                            items.splice(
                              result.destination.index,
                              0,
                              reorderedItem
                            );
                            handleOrdering(items);
                          }}
                        >
                          <div className="w-full  overflow-hidden bg-slate-50 py-5 px-4 ">
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-2xl font-bold">Gallery</h2>
                              <div className="flex gap-2">
                                {selectedMember?.length === 1 && (
                                  <Button
                                    buttonClasses={
                                      "!px-6 !h-8   justify-center bg-white !text-black !rounded-lg !border !border-black"
                                    }
                                    buttonLabel={"Default"}
                                    buttonFunction={handleDefault}
                                  />
                                )}
                                {selectedMember?.length > 0 && (
                                  <Button
                                    buttonClasses={
                                      "!px-6 !h-8  justify-center bg-white !text-red-500 !rounded-lg !border !border-red-500"
                                    }
                                    buttonLabel={"Delete"}
                                    buttonFunction={handleDeleteClick}
                                  />
                                )}
                              </div>
                            </div>

                            <Droppable droppableId="gallery">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="grid grid-cols-12 gap-4 overflow-y-scroll pt-4 pb-4 h-[calc(100vh-450px)] md:h-[calc(100vh-296px)] scroll-smooth "
                                  onScroll={(e) => {
                                    onScroll({
                                      scrollTop: e.target.scrollTop,
                                      clientHeight: e.target.clientHeight,
                                      scrollHeight: e.target.scrollHeight,
                                    });
                                  }}
                                >
                                  {albumList?.data?.map((item, index) => (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="relative border-white border w-full bg-white rounded-md shadow col-span-4 lg:col-span-2 h-32 gallery-item"
                                        >
                                          <div
                                            className="flex items-center justify-between "
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              id={`${item?._id}`}
                                              checked={selectedMember.some(
                                                (member) =>
                                                  member?._id === item?._id
                                              )}
                                              onChange={() =>
                                                handleSelectedMember(item)
                                              }
                                              className="absolute z-10 left-1 top-1 cursor-pointer w-4 h-4 border border-slate-300 focus:ring-transparent checked:rounded-full rounded-full checked:text-dark-teal-700   checked:hover:bg-dark-teal-700 checked:bg-dark-teal-700 "
                                            />
                                          </div>
                                          <button
                                            className="absolute z-10 -top-1 -right-2 shadow-md text-xs bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center close-icon"
                                            onClick={() => {
                                              setDeleteModal({
                                                isOpen: true,
                                                apiEndpoint: `gallery/delete-all`,
                                                method: "patch",
                                                data: item,
                                                payload: {
                                                  body: {
                                                    ids: [item?._id || ""],
                                                  },
                                                },
                                              });
                                            }}
                                            type="button"
                                          >
                                            <i class="fa-solid fa-xmark"></i>
                                          </button>
                                          <div className="w-full z-0 aspect-square overflow-hidden rounded-md">
                                            <Image
                                              src={
                                                item?.image ? item?.image : ""
                                              }
                                              alt=""
                                              width={220}
                                              height={220}
                                              className="w-full h-full object-cover"
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </DragDropContext>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-3 pl-4">
                      <div className="relative">
                        <ImageUploadSingle
                          label="Upload Image"
                          image={selectedImg}
                          setImage={setSelectedImg}
                          icon={"fa-regular fa-image"}
                          title={"Choose a file or drag & drop it here"}
                          isDirectUpload={true}
                          changeUpload={(value) => onSubmit(value)}
                        />
                      </div>
                      <div className=" mt-4 text-md font-semibold text-slate-500 tracking-none pb-2">
                        Default Image
                      </div>
                      <div className="h-48 w-full relative bg-white border-2 items-center rounded-lg border-slate-200 border-dashed">
                        <Image
                          src={defaultImage}
                          alt={""}
                          fill={true}
                          isLoader={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <DeleteModal
        open={deleteModal?.isOpen}
        data={{
          apiEndpoint: deleteModal?.apiEndpoint,
          method: deleteModal?.method,
          data: deleteModal?.data,
          payload: deleteModal?.payload,
        }}
        count={albumCount}
        setCount={setAlbumCount}
        onClose={() => setDeleteModal({ isOpen: false })}
        getResponse={getDeleteResponse}
      />
    </>
  );
};

export default AddGallery;
