import React, { useState } from "react";
import Button from "../forms/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NewModal from "./NewModal";

const LogoutModal = ({
  open = false,
  onClose = () => {},
  logoutHandler = () => {},
  customMessage = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await logoutHandler();
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");

      toast.success("Logged out successfully!");
      navigate("/admin/signin");
    } catch (error) {
      toast.error("Failed to logout. Please try again.");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <NewModal
      isOpen={open}
      setIsOpen={() => onClose()}
      size="sm"
      titleShow={false}
      watermark={false}
    >
      <div className="space-y-2 py-4">
        <div className="text-center font-bold  text-lg flex gap-2 justify-center text-carnation-950 font-Paytone">
          <span>
            <i className={"fa-regular fa-circle-exclamation"}></i>
          </span>
          Logout !
        </div>
        <div className="text-center my-3  text-slate-500">
          Are you sure you want to Logout ?
        </div>
        <div className="flex gap-3 justify-center !mt-6">
          <Button
            buttonIconPosition={"left"}
            buttonIcon={"fa-regular fa-close"}
            buttonClasses={"w-36 justify-center !bg-white !border-black !text-black"}
            buttonLabel={"Cancel"}
            buttonFunction={() => {
              onClose();
            }}
          />
          <Button
            buttonFunction={handleLogout}
            buttonIconPosition={"left"}
            buttonIcon={"fa-regular fa-sign-out"}
            buttonClasses={
              "!bg-red-500 !text-white w-36 justify-center hover:!bg-gray-800 hover:!text-white !duration-100"
            }
            buttonLabel={"Logout"}
            loading={isLoading}
            isDisable={isLoading}
          />
        </div>
      </div>
    </NewModal>
  );
};

export default LogoutModal;
