import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";

export default function SidebarItem({ data, size = "default" }) {
  const asPath = window.location.pathname;

  const itemSize = {
    default: "h-10 text-base",
    sm: "h-8 text-sm",
  };
  const textSize = {
    default: "text-sm font-medium",
    sm: "text-xs font-medium",
  };

  return (
    <>
      <Link
        to={data.link}
        className={classNames(
          "flex items-center px-4 space-x-2 transition-all duration-200 ease-in-out",
          itemSize[size],
          data.link === asPath
            ? "text-black bg-lemon"
            : "text-white bg-transparent group-hover/link:bg-chocolate/50"
        )}
      >
        <i className={classNames("fa-fw", data.icon)}></i>
        <span className={textSize[size]}>{data.name}</span>
      </Link>
    </>
  );
}
