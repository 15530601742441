import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";

const Button = ({
  buttonFunction = () => {},
  buttonIconPosition,
  buttonLabelClasses,
  buttonIconClasses,
  buttonEffect = "",
  loading = false,
  buttonClasses,
  buttonHasLink,
  buttonLabel,
  buttonType = "button",
  buttonIcon,
  buttonLink,
  isDisable = false,
  ...props
}) => {
  const effect = {
    filled:
      "z-0 before:content-[''] before:z-[-1] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black-600/50 before:scale-x-0 before:transition-all before:duration-200 before:origin-left hover:before:scale-x-100",
    hollow:
      "z-0 before:content-[''] before:z-[-1] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black-50 before:scale-x-0 before:transition-all before:duration-200 before:origin-left hover:before:scale-x-100",
  };

  return (
    <>
      {buttonHasLink ? (
        <Link
          href={buttonLink}
          className={classNames(
            "border border-transparent relative overflow-hidden flex justify-center items-center gap-2 bg-black text-white rounded-lg text-lg h-12 py-0 px-7 transition-all duration-300 hover:bg-white hover:border hover:border-black hover:text-black ease-in-out",
            buttonClasses,
            effect[buttonEffect],
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
        >
          {buttonIconPosition === "left" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
          {buttonLabel && (
            <span
              className={classNames(
                "text-base transition-all duration-200",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={classNames(
            "border border-transparent relative overflow-hidden flex justify-center items-center gap-2 bg-black text-white rounded-lg  text-lg h-12 py-0 px-7 transition-all duration-300 hover:bg-white hover:border hover:border-black hover:text-black ease-in-out",
            buttonClasses,
            effect[buttonEffect],
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
          onClick={buttonFunction}
          disabled={isDisable}
        >
          {buttonIconPosition === "left" && (
            <i
              className={classNames(
                "fa-fw",
                !loading
                  ? buttonIcon
                  : "fa-duotone fa-spinner-third animate-spin"
              )}
            ></i>
          )}
          {buttonLabel && (
            <span
              className={classNames(
                "text-base transition-all duration-200",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i
              className={classNames(
                "fa-fw",
                !loading
                  ? buttonIcon
                  : "fa-duotone fa-spinner-third animate-spin"
              )}
            ></i>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
