import React, { useEffect, useRef } from "react";
import TableAction from "./TableAction";
import Indentification from "../Indentification";
import Image from "../Image";
import { initialsValue } from "../../../helpers";
import { classNames } from "../../../helpers/classNames";
import Select from "../../forms/Select";
import Input from "../../forms/Input";
import Textarea from "../../forms/Textarea";
import Switch from "../../forms/Switch";
import Checkbox from "../../forms/Checkbox";

const Td = ({
  data,
  statusLabel,
  statusType,
  align,
  functions = () => {},
  actionData,
  checkboxChecked,
  onCheckboxChange,
  tableActionDropdownContainer,
}) => {
  const status = {
    success: "bg-green-100 text-green-600",
    danger: "bg-rose-100 text-rose-600",
    warning: "bg-amber-100 text-amber-600",
    info: "bg-sky-100 text-sky-600",
    "": "bg-slate-100 text-slate-600",
  };
  return (
    <>
      <td
        className={classNames(
          "py-3 px-2 max-w-[240px]",
          data.cellClass,
          data.align === "left"
            ? "text-left"
            : data.align === "right"
            ? "text-right"
            : data.align === "center"
            ? "text-center"
            : "",
          "last:right-0"
        )}
      >
        {data.type === "text" && (
          <div className="text-sm font-medium text-org-grayW">{data.data}</div>
        )}
        {data.type === "user" && (
          <div className="relative flex items-center">
            <Indentification
              initial={initialsValue(data?.data)}
              image={data?.image}
              alt={data.data}
              fill={true}
              className={""}
              size={"sm"}
            />
            <div className="text-md mx-2">{data.data}</div>
          </div>
        )}

        {data.type === "image" && (
          <div
            className={classNames(
              "relative w-8 h-8 min-w-[32px] overflow-hidden rounded-full bg-magenta-100"
            )}
          >
            <Image src={data.image} alt={""} fill={true} isLoader={false} />
          </div>
        )}
        {data?.type === "text-tooltip" && (
          <div
            className="relative inline-flex items-center"
            data-tooltip-id={`statusTooltip${data?._id}`}
            data-tooltip-place="right"
          >
            <div className={"text-sm font-normal text-slate-600 mx-2 "}>
              {data?.data}
            </div>
            {/* {data?.isTooltip && (
              <Tooltip
                id={`statusTooltip${data?._id}`}
                className="text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => data?.toolTipData}
              />
            )} */}
          </div>
        )}
        {/* {data.type === "status" && (
          <div
            className={classNames(
              "flex items-center",
              data.align === "left"
                ? "justify-start"
                : data.align === "right"
                ? "justify-end"
                : data.align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <Select
              xPlacement={"bottomLeft"}
              dropdownclassName={"!min-w-[120px]"}
              dropdownButtonclassName={
                "!border-transparent hover:!border-org-border !h-8 !w-auto group/select !font-normal !p-0 !pr-6 !border-none"
              }
              buttonArrowIconClasses={
                "opacity-0 group-hover/select:opacity-100"
              }
              selectedValue={
                data.dataSelected
                  ? data.dataSelected
                  : { _id: -1, name: "Select" }
              }
              dropdownData={data.data}
              onChange={(val) => (data.onChange ? data.onChange(val) : "")}
            />
          </div>
        )} */}
        {data.type === "status" && (
          <div
            onClick={(e) =>
              functions && functions({ data: data?.statusLabel, event: e })
            }
            className={classNames(
              "flex items-center cursor-pointer capitalize",
              align === "left"
                ? "justify-start"
                : align === "right"
                ? "justify-end"
                : align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <div
              className={
                "text-xs font-medium leading-tight rounded py-1 px-2 " +
                status[data.statusType]
              }
            >
              {data?.statusLabel}
            </div>
          </div>
        )}
        {data.type === "action" && (
          <div className="flex items-center justify-end">
            <TableAction
              data={data.actionData}
              xPlacement={"right"}
              dropdownContainer={tableActionDropdownContainer}
            />
          </div>
        )}
        {data.type === "select" && (
          <div
            className={classNames(
              "flex items-center",
              data.align === "left"
                ? "justify-start"
                : data.align === "right"
                ? "justify-end"
                : data.align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <Select
              xPlacement={"bottomLeft"}
              dropdownclassName={"!w-full !min-w-[160px]"}
              dropdownButtonclassName={
                "!border-transparent hover:!border-org-border !h-8 !w-auto group/select !font-normal"
              }
              buttonArrowIconClasses={
                "opacity-0 group-hover/select:opacity-100"
              }
              selectedValue={
                data.dataSelected
                  ? data.dataSelected
                  : { _id: -1, name: "Select" }
              }
              dropdownData={data.data}
            />
          </div>
        )}
        {data.type === "input" && (
          <div
            className={classNames(
              "flex items-center",
              data.align === "left"
                ? "justify-start"
                : data.align === "right"
                ? "justify-end"
                : data.align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <Input
              divClasses={"!w-auto"}
              inputType={"text"}
              value={data.data}
              inputClasses={
                "!h-8 !w-auto !border-transparent hover:!border-org-border focus:!border-org-border"
              }
            />
          </div>
        )}
        {data.type === "textarea" && (
          <div
            className={classNames(
              "flex items-center",
              data.align === "left"
                ? "justify-start"
                : data.align === "right"
                ? "justify-end"
                : data.align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <Textarea />
          </div>
        )}
        {data.type === "switch" && (
          <div
            className={classNames(
              "flex items-center",
              data.align === "left"
                ? "justify-start"
                : data.align === "right"
                ? "justify-end"
                : data.align === "center"
                ? "justify-center"
                : ""
            )}
          >
            <Switch checked={data.data} functions={data.setData} />
          </div>
        )}
        {data.type === "avatar" && (
          <div
            className={classNames(
              "relative w-16 h-16 min-w-[32px] overflow-hidden rounded-md bg-magenta-100"
            )}
          >
            <Image
              src={data.image}
              alt={""}
              layout="fill"
              objectFit="cover"
              isLoader={false}
            />
          </div>
        )}
        {data?.type === "checkbox" && (
          <Checkbox isChecked={checkboxChecked} onChange={onCheckboxChange} />
        )}
      </td>
    </>
  );
};

export default Td;
