import { apiRequest } from "../util/apiUtils";

export const AdminSignin = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "admin-login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};
