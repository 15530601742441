import React, { useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";

import { classNames } from "../../../helpers/classNames";
import Search from "../../forms/Search";
import Checkbox from "../../forms/Checkbox";
import Radio from "../../forms/Radio";

const TableFilter = ({
  xPlacement,
  filterData = [],
  isFilterSearch = true,
  onFilter = () => {},
  filterSearch = () => {},
  ...props
}) => {
  const popperElRef = useRef(null);
  const buttonRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popperElRef.current &&
        !popperElRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Menu as="div">
        {({}) => (
          <>
            <div className="relative group">
              <Menu.Button
                ref={(el) => {
                  buttonRef.current = el;
                  setTargetElement(el);
                }}
                className="flex items-center"
                onClick={() => setOpen(!open)}
              >
                <div
                  className={classNames(
                    "relative flex items-center justify-center text-sm w-6 h-6 rounded  text-white hover:shadow-lg transition-all bg-black duration-300 ease-in-out transform  focus:ring-1 focus:ring-offset-1 focus:ring-lemon",
                    selected.length > 0 ? "ring ring-offset-1" : ""
                  )}
                >
                  <i className="fa-solid fa-fw fa-filter-list"></i>
                </div>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              leave="transition duration-100 ease-out"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                ref={popperElRef}
                className="w-64 origin-top-right text bg-white border border-gray-200 divide-y divide-gray-100 rounded-lg shadow-lg ring-0"
              >
                {!isFilterSearch && (
                  <div className="py-3 px-4 flex items-center justify-center gap-3 bg-gradient-to-r from-purple-50 to-pink-50 rounded-t-lg">
                    <Search
                      placeholder={"Search"}
                      divClasses={"!h-8"}
                      iconClasses={"!h-8"}
                      inputClasses={"font-normal text-gray-700"}
                      search={(e) => filterSearch(e)}
                    />
                    <div
                      onClick={() => {
                        if (selected?.length > 0) {
                          setSelected([]);
                          onFilter([]);
                        }
                      }}
                      className="border border-gray-300 bg-white flex items-center text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-lg cursor-pointer shadow-sm"
                    >
                      <i className="fa-regular fa-arrows-rotate"></i>
                    </div>
                  </div>
                )}
                <div className="py-2 max-h-60 divide-y text-black divide-gray-100 overflow-auto scroll-smooth">
                  <div
                    onClick={() => {
                      const isAllSelected =
                        selected?.length === filterData?.length;
                      const updatedSelection = isAllSelected ? [] : filterData;
                      setSelected(updatedSelection);
                      onFilter(updatedSelection);
                    }}
                    className="cursor-pointer hover:bg-purple-50 transition-all duration-200"
                  >
                    <div className="relative py-2 px-4">
                      <Checkbox
                        checkboxName={"filter_checkbox"}
                        checkboxInputClass={"!h-5 !w-5"}
                        checkboxClass={"!h-5 !w-5"}
                        checked={selected?.length === filterData?.length}
                        checkboxLabel={"Select All"}
                        checkboxLableClass={"font-medium text-sm"}
                      />
                    </div>
                  </div>
                  {filterData.map((item, index) => (
                    <div
                      key={index?.toString()}
                      onClick={() => {
                        const isSelected = selected
                          ?.map((selectedItem) => selectedItem?.value)
                          ?.includes(item?.value);
                        const updatedSelection = isSelected
                          ? selected.filter(
                              (s_item) => s_item?.value !== item?.value
                            )
                          : [...selected, item];
                        setSelected(updatedSelection);
                        onFilter(updatedSelection);
                      }}
                      className="cursor-pointer text hover:bg-purple-50 transition-all duration-200"
                    >
                      {item.type === "checkbox" && (
                        <div className="relative py-2 px-4">
                          <Checkbox
                            checkboxName={"filter_checkbox"}
                            checkboxInputClass={"!h-5 !w-5"}
                            checkboxClass={"!h-5 !w-5"}
                            checked={selected?.some(
                              (data) => data?.value === item?.value
                            )}
                            checkboxLabel={item.name}
                            checkboxLableClass={"font-medium text-sm"}
                          />
                        </div>
                      )}
                      {item.type === "radio" && (
                        <div className="relative py-2 px-4">
                          <Radio
                            radioName={"filter_radio"}
                            radioInputclassName={""}
                            isChecked={item.isChecked}
                            radioLabel={item.name}
                            radioLableclassName={"font-normal text-sm"}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default TableFilter;
